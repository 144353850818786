import React, { useState } from 'react'
import qs from "query-string"
import plants from "../../vendor/media/Plants_R1 (1).png";
import CONSTANTS from '../../../api/constants'
import login_logo from '../../vendor/media/OnTrial Logo Main.png'
import Api from '../../../api'
import bg from '../../vendor/media/background_login_cropped.jpg'
import zxcvbn from "zxcvbn";


const CreateAccount = () => {
    // let signUpState = window.history.state
    let signUpState = qs.parse(window.location.search);
    window.history.pushState({}, "Create Account", "/enroll/create");


    const meetsLengthRequirement = str => (str.length >= 8);
    const meetsUppercaseCharRequirement = str => /[A-Z]/.test(str);
    const meetsNumericalCharRequirement = str => /[0-9]/.test(str);
    const meetsSpecialCharRequirement = str => /[!@#$%^&*()\-_+={}[\]|<>;|/]/.test(str);

    let initialStateObj = {
        username: '',
        secret: '',
        strength: null,
        secretMatch: null,
        confirmSecret: '',
        attempt: CONSTANTS.ATTEMPT.SIGNUP.NONE,
        ...signUpState
    }

    const [input, setInput] = useState({ ...initialStateObj })

    const renderPwStrength = () => {
        let strengthClassName = "";
        let strengthText = "Very Weak";
        let strengthPercentage = 0;
        const indicatorMax = 200;
        let indicatorWidth = 15;
        if (input.strength !== null && input.strength > 0) {
            strengthPercentage = input.strength / 4;
            indicatorWidth = indicatorMax * strengthPercentage;
        }
        switch (input.strength) {
            case 4:
                strengthClassName += "very-strong";
                strengthText = "Very Strong"
                break;
            case 3:
                strengthClassName += "strong";
                strengthText = "Strong"
                break;
            case 2:
                strengthClassName += "moderate";
                strengthText = "Moderate";
                break;
            case 1:
                strengthClassName += "weak";
                strengthText = "Weak";
                break;
            case 0:
            default:
                strengthClassName += "very-weak";
                strengthText = "Very Weak"
                break;
        }
        return (
            <>
                <span className={`strength ${strengthClassName}`}> {strengthText} </span><br />
                <div
                    className="strength indicator"
                    style={{
                        maxWidth: `${indicatorMax}px`,
                        width: `${indicatorMax}px`
                    }}
                >
                    <div
                        className={`strength indicator internal ${strengthClassName}`}
                        style={{
                            maxWidth: `${indicatorWidth}px`,
                            width: `${indicatorWidth}px`
                        }}
                    />
                </div>
                <br />
                {
                    meetsLengthRequirement(input.secret) ?
                        '' :
                        <>
                            <span className="strength weak"> Password must be at least 8 characters in length </span>
                            <br />
                        </>
                }
                {
                    meetsUppercaseCharRequirement(input.secret) ?
                        '' :
                        <>
                            <span className="strength weak"> Password must contain at least one uppercase character </span>
                            <br />
                        </>
                }
                {
                    meetsNumericalCharRequirement(input.secret) ?
                        '' :
                        <>
                            <span className="strength weak"> Password must contain at least one numerical character </span>
                            <br />
                        </>
                }
                {
                    meetsSpecialCharRequirement(input.secret) ?
                        '' :
                        <>
                            <span className="strength weak"> {`Password must contain at least one special character (!@#$%^&*()-_+={}[]|><;|/)`} </span>
                            <br />
                        </>
                }
            </>
        )
    }

    const onFormValueChange = (e) => {
        e.preventDefault()
        e.persist()
        let _input = input
        if (e.target.name === "secret") {
            _input.strength = zxcvbn(e.target.value).score
        }

        if (e.target.name === "secret" || e.target.name === "confirmSecret") {
            _input.secretMatch = (e.target.value === input.secret)
        }

        _input[e.target.name] = e.target.value
        setInput({ ..._input })
    }

    const onFormSubmit = async (e) => {
        e.preventDefault()
        e.persist()
        if (input.secret !== input.confirmSecret) {
            alert("Your passwords do not match!");
            return;
        }

        const defaultRequirementsAlertText = "We found the following problem or problems with your password. \r\n";
        let requirementsAlertText = defaultRequirementsAlertText
        if (!meetsLengthRequirement(input.secret)) requirementsAlertText += "\r\nDoes not meet the minimum length of 8 characters.";
        if (!meetsUppercaseCharRequirement(input.secret)) requirementsAlertText += "\r\nDoes not contain one or more uppercase character.";
        if (!meetsNumericalCharRequirement(input.secret)) requirementsAlertText += "\r\nDoes not contain one or more numbers.";
        if (!meetsSpecialCharRequirement(input.secret)) requirementsAlertText += "\r\nDoes not contain one or more special characters";
        if( requirementsAlertText !== defaultRequirementsAlertText ) {
            alert(requirementsAlertText);
            return;
        }

        // console.log(input)
        let state = input
        // const _attempt = state.attempt
        // const _confirm = state.confirmSecret
        delete state.attempt;
        delete state.confirmSecret;
        delete state.strength;
        delete state.secretMatch;
        const onSignupSuccess = (r) => console.log(r)

        let signupEvent = new Api.Events.User.SignupEvent(Api.constants.events.user.signup, { ...state }, onSignupSuccess)
        await signupEvent.submit()

    }

    return (
        <>
            <div id={"signup-container"}>



                <div id="signup-header-block" className='col-span-1 row-span-1' />
                <div className='col-span-1 row-span-1 text-center'>
                    <br />
                    <br />
                    <img className='logo-md' src={login_logo} alt='OnTrial Logo' />
                    <h1> Sign Up (Cont.) </h1>
                    {/* <div id='create-account-box'></div> */}
                    <div id='create-account-form'>
                        <form onSubmit={onFormSubmit}>
                            <label>First Name</label>
                            <input type='text' name='firstName' value={input.firstName} onChange={onFormValueChange} required disabled />
                            <label>Last Name</label>
                            <input type='text' name='lastName' value={input.lastName} onChange={onFormValueChange} required disabled />
                            <label>Email</label>
                            <input type='email' name='email' value={input.email} onChange={onFormValueChange} required disabled />
                            <label>Username</label>
                            <span className='required-label'>*</span>
                            <input type='text' name='username' value={input.username} onChange={onFormValueChange} required />
                            <label>Password</label>
                            <span className='required-label'>*</span>
                            <input type='password' name='secret' value={input.secret} onChange={onFormValueChange} required />
                            {/* <br/> */}
                            {
                                input.strength !== null ?
                                    <>
                                        {renderPwStrength()}

                                        <br />
                                    </>
                                    :
                                    <></>
                            }
                            <label>Confirm Password</label>
                            <span className='required-label'>*</span>
                            <input type='password' name='confirmSecret' value={input.confirmSecret} onChange={onFormValueChange} required />
                            {
                                input.secretMatch !== null ?
                                    (input.secretMatch === false ?
                                        <>
                                            <span className="strength very-weak"> Your passwords do not match </span>
                                            <br />
                                            <br />
                                        </>
                                        :
                                        <></>
                                    )
                                    :
                                    <></>
                            }
                            <button type='submit' className='btn-primary' > Sign Up </button>
                        </form>
                        <br />
                        <div className='text-center'>
                            <span> Already have an account? </span>
                            <br />
                            <br />
                            <button onClick={() => window.location.href = '/'}> Log In </button>
                        </div>

                        <div className="col-md-2 col-lg-3 d-sm-none d-md-block d-lg-block"></div>



                    </div>
                </div>
                <img className='signup-background-image col-span-1 row-span-1' src={bg} alt='whoops' />
                <div id='signup-footer-block' className='col-span-1 row-span-1'></div>
            </div>

        </>
    )
}

export default CreateAccount
