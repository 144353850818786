import User from '../../User'
import OnTrialEvent from '../OnTrialEvent'

class LoginEvent extends OnTrialEvent {
    constructor(eventType, data, callback) {
        super(eventType, data, callback)
        this.requiredProperties = [
            this.constants.fieldNames.user.username,
            this.constants.fieldNames.user.secret
        ]
        this.validateProperties()
    }

    async submit() {
        let userLogin = await User.login({ username: this.data.username, secret: this.data.secret })
        console.log('From login event')
        console.log(userLogin)
        if (userLogin.token) {
            sessionStorage.setItem('session', userLogin.token)
            if (this.callback) {
                return this.callback(userLogin.user)
            }
        } else {
            if ( userLogin.error === 'User not authorized by OnTrial'){
                window.location.href = '/enroll/pending'
            } else {
                sessionStorage.clear()
                return window.location.href = '/'
            }
        }
    }


}

export default LoginEvent