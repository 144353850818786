import Api from '../../api'
import Resource from './Resource'
class AppState extends Resource {
    constructor(data) {
        super(data)
        // console.log('AppState - here is data')
        // console.log(data)
        if (!data) {
            data = {
                location: Api.constants.DASHBOARD_LOCATIONS.ORDERS,
                tab: Api.constants.ORDERS_LOCATIONS.MY_CASES,
                loaded: false,
                user: {},
                matters: [],
                orders: [],
                newOrderOverride: false,
                overrideContent: {},
                history: {}
            }
        }
        this.location = data.location || this.constants.DASHBOARD_LOCATIONS.ORDERS
        this.tab = data.tab || this.constants.ORDERS_LOCATIONS.MY_CASES
        this.loaded = data.loaded || false
        this.user = data.user || {}
        this.matters = data.matters || []
        this.orders = data.orders || []
        this.newOrderOverride = data.newOrderOverride || false
        this.overrideContent = data.overrideContent || {}
        this.history = data.history || {}
    }

    async reloadUser(callback) {
        let fetchUserResponse = await Api.Resources.User.fetch()
        if (!fetchUserResponse.error && fetchUserResponse.user) {
            console.log('Got user')
            let user = new Api.Resources.User(fetchUserResponse.user)
            // console.log(appStateObj)
            let newAppState = this.get()
            newAppState.user = user
            newAppState.orders = user.getOrders()
            newAppState.matters = user.getMatters(user.getOrders())
            console.log('Here is new appState')
            console.log(newAppState)
            if (callback) {
                return callback(newAppState)
            } else {
                throw new Error('Must include callback hook')
            }
        } else {
            console.log('could not get user')
            let newAppState = this.get()
            if (callback) {
                return callback(newAppState)
            } else {
                throw new Error('Must include callback hook')
            }
        }
    }

    get() {
        return {
            location: this.location,
            tab: this.tab,
            loaded: this.loaded,
            user: this.user,
            matters: this.matters,
            orders: this.orders,
            newOrderOverride: this.newOrderOverride,
            overrideContent: this.overrideContent,
            history: this.history
        }
    }

    modify(props) {
        let toModify = Object.keys(props)
        for ( let p = 0; p< toModify.length; p++){
            this[toModify[p]] = props[toModify[p]]
        }
        return this
    }

}

export default AppState