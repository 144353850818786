import React from 'react'
import plants from "../../vendor/media/Plants_R1 (1).png";



const Main = (props) => {
    return <div id='orders-main'>
        {props.children}
        <img src={plants} className="plants" alt="Plants Background" />
        {/* <div id="dashboard-footer-block" className="col-span-1 row-span-1"></div> */}
    </div>
}

const LeftMain = (props) => {
    return (
        <div id='orders-left' className='col-span-1'>
            {props.children}

        </div>
    )
}

const LeftList = (props) => {
    return <div className='rolodex-quickview'>
        <div className='rolodex-quickview-inner' >
            {props.children}
        </div>
    </div>
}

const LeftNew = (props) => {
    return <div className='' id='new-items-div'>
        <div className=''>
            {props.children}
        </div>
    </div>
}


const RightMain = (props) => {
    return <div id='orders-right' className='col-span-1'>
        {props.children}
    </div>
}

export default {
    Main: Main,
    Left: {
        Main: LeftMain,
        List: LeftList,
        New: LeftNew
    },
    Right: {
        Main: RightMain
    }

}