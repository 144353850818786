import React from 'react'
import CONSTANTS from '../../../api/constants'
import './orders.css'

const OrdersMenu = ({ tab, changeTab }) => {
    const isActive = ((tab) => {
        return (loc) => {
            if (tab === loc) {
                return true
            } else {
                return false
            }
        }
    })(tab)

    return (
        <>
            <div id='orders-menu-container'>
                <h3 className={`orders-menu-item text-left ${isActive(CONSTANTS.ORDERS_LOCATIONS.MY_CASES) ? 'menu-item-active' : ''}`} onClick={() => changeTab(CONSTANTS.ORDERS_LOCATIONS.MY_CASES)}> My Matters </h3>
                <h3 className={`orders-menu-item text-left ${isActive(CONSTANTS.ORDERS_LOCATIONS.OPEN_ORDERS) ? 'menu-item-active' : ''}`} onClick={() => changeTab(CONSTANTS.ORDERS_LOCATIONS.OPEN_ORDERS)}> Open Orders </h3>
                <h3 className={`orders-menu-item text-left ${isActive(CONSTANTS.ORDERS_LOCATIONS.CLOSED_ORDERS) ? 'menu-item-active' : ''}`} onClick={() => changeTab(CONSTANTS.ORDERS_LOCATIONS.CLOSED_ORDERS)}> Closed Orders </h3>

            </div>
        </>
    )
}

export default OrdersMenu 