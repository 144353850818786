const FIELDNAMES = {
    user: {
        id: 'id',
        firstName: 'firstName',
        lastName: 'lastName',
        email: 'email',
        phone: 'phone',
        company: 'company',
        role: 'role',
        address: 'address',
        addressContinued: 'addressContinued',
        city: 'city',
        state: 'state',
        postalCode: 'postalCode',
        username: 'username',
        secret: 'secret',
        secret1: 'secret1',
        secret2: 'secret2'

    },
    order: {
        case: 'case',
        notes: 'notes',
        service: 'service',
        isRush: 'isRush',
        scheduleCall: 'scheduleCall',
        neededBy: 'neededBy',
        clioDocuments: 'clioDocuments',
        clioDocumentOptions: 'clioDocumentOptions',
        directDocuments: 'directDocuments',
    },
    matter: {
        clientName: 'clientName',
        displayNumber: 'displayNumber',
        matterId: 'matterId',
        isClio: 'isClio'
    },
    appState: {
        location: 'location',
        tab: 'tab',
        loaded: 'loaded',
        user: 'user',
        matters: 'matters',
        orders: 'orders',
        newOrderOverride: 'newOrderOverride',
        overrideContent: 'overrideContent',
        history: 'history',
        type: 'type',
        loc: 'loc'
    }
}

const FORMS = {
    signup: {
        fields: {
            state: {
                type: 'select',
                options: [
                    { label : 'Select your state', value : 'default'},
                    { label: 'Alabama', value: 'AL' },
                    { label: 'Alaska', value: 'AK' },
                    { label: 'Arizona', value: 'AZ' },
                    { label: 'Arkansas', value: 'AR' },
                    { label: 'California', value: 'CA' },
                    { label: 'Colorado', value: 'CO' },
                    { label: 'Connecticut', value: 'CT' },
                    { label: 'Delaware', value: 'DE' },
                    { label: 'Florida', value: 'FL' },
                    { label: 'Georgia', value: 'GA' },
                    { label: 'Hawaii', value: 'HI' },
                    { label: 'Idaho', value: 'ID' },
                    { label: 'Illinois', value: 'IL' },
                    { label: 'Indiana', value: 'IN' },
                    { label: 'Iowa', value: 'IA' },
                    { label: 'Kansas', value: 'KS' },
                    { label: 'Kentucky', value: 'KY' },
                    { label: 'Louisiana', value: 'LA' },
                    { label: 'Maine', value: 'ME' },
                    { label: 'Maryland', value: 'MD' },
                    { label: 'Massachusetts', value: 'MA' },
                    { label: 'Michigan', value: 'MI' },
                    { label: 'Minnesota', value: 'MN' },
                    { label: 'Mississippi', value: 'MS' },
                    { label: 'Missouri', value: 'MO' },
                    { label: 'Montana', value: 'MT' },
                    { label: 'Nebraska', value: 'NE' },
                    { label: 'Nevada', value: 'NV' },
                    { label: 'New Hampshire', value: 'NH' },
                    { label: 'New Jersey', value: 'NJ' },
                    { label: 'New Mexico', value: 'NM' },
                    { label: 'New York', value: 'NY' },
                    { label: 'North Carolina', value: 'NC' },
                    { label: 'North Dakota', value: 'ND' },
                    { label: 'Ohio', value: 'OH' },
                    { label: 'Oklahoma', value: 'OK' },
                    { label: 'Oregon', value: 'OR' },
                    { label: 'Pennsylvania', value: 'PA' },
                    { label: 'Rhode Island', value: 'RI' },
                    { label: 'South Carolina', value: 'SC' },
                    { label: 'South Dakota', value: 'SD' },
                    { label: 'Tennessee', value: 'TN' },
                    { label: 'Texas', value: 'TX' },
                    { label: 'Utah', value: 'UT' },
                    { label: 'Vermont', value: 'VT' },
                    { label: 'Virginia', value: 'VA' },
                    { label: 'Washington', value: 'WA' },
                    { label: 'West Virginia', value: 'WV' },
                    { label: 'Wisconsin', value: 'WI' },
                    { label: 'Wyoming', value: 'WY' }
                ]

            }
        }
    }
}

const EVENTS = {
    app: {
        pageLoad: 'pageLoad',
        navigation: 'navigation',
        state: {
            type: {
                dashboard: 'dashboard',
                tab: 'tab'
            }
        }
    },
    order: {
        submit: 'submitOrder'
    },
    matter: {
        submit: 'submitMatter'
    },
    user: {
        login: 'submitLogin',
        signup: 'submitSignup',
        requestPasswordReset: 'requestPasswordReset',
        updatePassword: 'updatePassword'

    }
}

const ERRORS = {
    get: {
        user: 'Could not GET user',
        matter: 'Could not GET matter',
        matters: 'Could not GET matters',
        order: 'Could not GET order',
        service: 'Could not GET service',
        document: 'Could not GET service'
    },
    create: {
        user: 'Could not create user',
        matter: 'Could not create matter',
        clioMatter: 'Could not create OnTrial matter from Clio'
    },
    cast: {
        user: 'Could not cast object to instance of User',
        matter: 'Could not cast object to instance of Matter',
        order: 'Could not cast object to instance of Order',
        service: 'Could not cast object to instance of Service',
        document: 'Could not cast object to instance of Document'
    },
    parse: {
        subjectUrl: 'Could not parse subject URl'
    },
    customAction: {
        clio: {
            user: {
                get: 'Could not GET user',
                cast: 'Could not cast object to instance of User'
            },
            matter: {},
            render: {},
        }
    }
}
const LOCATIONS = {
    dashboard: {
        orders: 'orders',
        account: 'account',
        help: 'help'
    },
    orders: {
        myMatters: 'myMatters',
        openOrders: 'openOrders',
        closedOrders: 'closedOrders'
    }
}

const CONSTANTS = {
    DASHBOARD_LOCATIONS: {
        ORDERS: 'orders',
        ACCOUNT: 'account',
        HELP: 'help'

    },
    ORDERS_LOCATIONS: {
        MY_CASES: 'myMatters',
        OPEN_ORDERS: 'openOrders',
        CLOSED_ORDERS: 'closedOrders'
    },
    ATTEMPT: {
        SIGNUP: {
            PASSWORD_MISMATCH: 'passwordMismatch',
            SUCCESS: 'success',
            ERROR: 'error',
            NONE: 'none'
        },
        SIGNIN: {
            SUCCESS: 'success',
            ERROR: 'error',
            NONE: 'none'
        }
    },
    locations: LOCATIONS,
    error: ERRORS,
    fieldNames: FIELDNAMES,
    events: EVENTS,
    forms : FORMS 

}

export default CONSTANTS