import React, { useState } from "react";
import login_logo from "../../vendor/media/OnTrial Logo Main.png";
import plants from "../../vendor/media/Plants_R1 (1).png";
import bg from '../../vendor/media/background_login_cropped.jpg'
import Api from '../../../api'
import CONSTANTS from "../../../api/constants";
const RequestPasswordReset = () => {
    const [input, setInput] = useState({
        email: ""
    });

    const onFormValueChange = e => {
        e.preventDefault();
        e.persist();
        let _input = input;
        _input[e.target.name] = e.target.value;
        setInput({ ..._input });
    };

    async function onFormSubmit(e){
        e.preventDefault();


        const onRequestPasswordResetSuccess = (user) => {
            window.history.pushState({ ...input }, "request password reset", "/password/reset/request");
            window.location.href = "/";
        }
        let requestPasswordResetEvent = new Api.Events.User.RequestPasswordResetEvent(Api.constants.events.user.requestPasswordReset, input, onRequestPasswordResetSuccess);
        await requestPasswordResetEvent.submit();
    };

    return (
        <>
            <div id="signup-header-block" className='col-span-1 row-span-1' />
            <div className="signup-bg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 col-lg-3 d-sm-none d-md-block d-lg-block"></div>
                        <div className="col-sm-12 col-md-8 col-lg-6">
                            <div className="text-center">
                                <br />
                                <br />
                                <img className="logo-md" src={login_logo} alt="OnTrial Logo" />
                                <br />
                                <br />
                                {/* <div id='login-box'></div> */}
                                <div id='login-form'>
                                    <h1> Reset OnTrial Password </h1>
                                    <form onSubmit={onFormSubmit}>
                                        <label> Enter the email associated with your OnTrial account </label>
                                        <input
                                            type="text"
                                            placeholder="johndoe@gmail.com"
                                            value={input.email}
                                            onChange={e => {
                                                e.preventDefault();
                                                e.persist();
                                                setInput({
                                                    email: e.target.value});
                                            }}
                                        />

                                        <button type="submit" className="btn-primary">
                                            {" "}
                                            Send Password Reset Email{" "}
                                        </button>
                                    </form>
                                    <br />
                                </div>

                            </div>
                        </div>
                        {/* <div className="col-md-2 col-lg-3 d-sm-none d-md-block d-lg-block"></div> */}
                    </div>
                </div>
                <img src={plants} className="plants" alt="Plants Background" />
                <div id="login-footer-block" ></div>
            </div>


        </>
    );
};

export default RequestPasswordReset;
