import React from 'react'
import qs from 'query-string'

const Redirect = () => {


    const handleConfirmationEvent = (function (event) {
        return async (event) => {
            event.preventDefault()
            if( event.isTrusted && event.key === 'close' ){
                console.log(event)
                window.open('', '_self', '')
                window.close()
            }
            return event
        }
    })


    let params = qs.parse(window.location.search)
    console.log(params)
    
    if (Object.keys(params).indexOf('code')>-1) {
        let data = JSON.stringify({ code: params.code })
        window.localStorage.clear()
        window.localStorage.setItem("redirect", data)
        console.log('set')
        window.onstorage = (e)=>handleConfirmationEvent(e)(e)
    }
 




    return (
        <>
           <div className='text-center' style={{ padding: '24rem'}}>
                <h1> Please wait while we verify your account... </h1>
           </div>
        </>
    )
}

export default Redirect