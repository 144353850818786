import React, { useState } from 'react'
// import Forms from '../forms'
import Layout from './Layout'
import Card from '../card'
import OrdersMenu from './OrdersMenu'
import FormSwitch from './FormSwitch'
import Icons from "../icons";

const ClosedOrders = ({ tab, changeTab, matters, orders, user, reload, formManager }) => {
    const [active, setActive] = useState(0)

    return (
        <>
            <Layout.Main>
                <Layout.Left.Main>
                    <OrdersMenu tab={tab} changeTab={changeTab} />
                    <Layout.Left.New>
                        <button className='btn-primary' onClick={() => formManager.open('newMatter')}>CREATE NEW MATTER +</button>
                        {/* <button className='btn-green new-item-button' onClick={() => formManager.open('newOrder')}> New Order </button><br /><br /> */}
                    </Layout.Left.New>
                    <Layout.Left.List>
                        {
                            orders.length > 0 ?
                                orders.map((o, n) => <Card.Order
                                    key={n ^ 4 * 0.179}
                                    order={o}
                                    matters={matters}
                                    active={n === active}
                                    setActive={(closeForm) => {
                                        if (closeForm) {
                                            formManager.close()
                                        }
                                        setActive(n)
                                    }}
                                />) :
                                <>
                                    <span className="ontrial-copy"> Looks like you haven't got any closed orders, yet.</span>
                                    <br />
                                    <span className="ontrial-copy">  Once you do, this is where they'll appear. </span>
                                </>

                        }
                    </Layout.Left.List>
                </Layout.Left.Main>
                <Layout.Right.Main>
                    <FormSwitch
                        formManager={formManager}
                        reload={reload}
                        user={user}
                        renderDefault={() => (
                            orders.length > 0 ?
                                <Card.Panel.Order order={orders[active]} matters={matters} /> :
                                ''
                        )}
                    />
                </Layout.Right.Main>
            </Layout.Main>
        </>
    )
}

export default ClosedOrders