import React, { useState } from 'react'
import Card from '../card'
import OrdersMenu from './OrdersMenu'
import Layout from './Layout'
// import Forms from '../forms'
import FormSwitch from './FormSwitch'
import Icons from '../icons'

const MyMatters = ({ tab, changeTab, matters, orders, user, reload, formManager }) => {
    const [active, setActive] = useState(0)

    return (
        <>
            <Layout.Main>
                <Layout.Left.Main>
                    <OrdersMenu tab={tab} changeTab={changeTab} />
                    <Layout.Left.New>
                        <button className='btn-primary' onClick={() => formManager.open('newMatter')}>CREATE NEW MATTER +</button>
                        {/* <button className='btn-green new-item-button' onClick={() => formManager.open('newOrder')}> New Order </button><br /><br /> */}
                    </Layout.Left.New>
                    <Layout.Left.List>

                        {
                            matters.length > 0 ?
                                matters.map((m, n) => {
                                    return (
                                        <Card.Matter
                                            key={n * 29.6}
                                            matter={m}
                                            active={n === active} setActive={(closeForm) => {
                                                if (closeForm) {
                                                    formManager.close()
                                                }
                                                setActive(n)
                                            }}
                                            formManager={formManager} />
                                    )
                                })
                                :
                                'Create a matter to get started!'

                        }
                    </Layout.Left.List>
                </Layout.Left.Main>
                <Layout.Right.Main>
                    <FormSwitch
                        formManager={formManager}
                        user={user}
                        reload={reload}
                        renderDefault={() =>
                            matters.length > 0 ?
                                <Card.Panel.Matter matter={matters[active]} matters={matters} formManager={formManager} />
                                : ''
                        }
                    />

                </Layout.Right.Main>
            </Layout.Main>
        </>
    )
}

export default MyMatters