import React, { useState } from "react";
import "./card.css";
import Api from "../../../api"

const PlainCard = ({ variant, plain, children }) => {
  return (
    <>
      <div
        className={`${
          variant ? variant : ""
          } card corral card-panel hide-scrollbar ${plain ? "" : "active-side"}`}
      >
        {children}
      </div>
    </>
  );
};

const MatterCard = ({ matter, active, setActive, formManager }) => {
  // console.dir({ matter, active, setActive, formManager })

  const onNewOrderClick = () => {
    formManager.override("newOrder", matter);
  };

  return (
    <>
      <div className={`card ${active ? "active" : ""}`} onClick={(e) => {
        if (e.target.name !== 'newOrder') {
          setActive(true)
        } else if (e.target.name === 'newOrder' && !active) {
          setActive(false)
        }
      }}>
        <div className="card-body">
          <div className="col-span-1">
            <span className="ontrial-h2">{matter.getDisplayName()}</span>
            <br />
            <span className="ontrial-subtitle">{matter.getClientName()}</span>
            <br />
            <span className="ontrial-info">
              Opened {matter.getOpenDate()}

            </span>
          </div>
          <div className="col-span-1 text-right">

            <p className="text-meta text-medium">
              {matter.getOrderCount()}{" "}
              {matter.getOrderCount() === 0 ? "Orders" : matter.getOrderCount() > 1 ? 'Orders' : 'Order'}
            </p>
            <p className="order-link">{matter.getOpenOrderCount()} Open</p>
            <p className="order-link">{matter.getClosedOrderCount()} Closed</p>
            <br />
            {/* <button className="btn-primary" name='newOrder' onClick={onNewOrderClick}>
              {" "}
              New Order{" "}
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

const OrderCard = ({ order, matters, active, setActive, rolodex }) => {

  // console.dir(({ order, matters, active, setActive, rolodex }))
  let matter = order.getMatter(matters);
  // console.dir(matter)
  return (
    <>
      <div className={`card ${active ? "active" : ""}`} onClick={(e) => {
        if (!active) {
          setActive(true)
        }
      }}>
        <div className="card-body">
          <div className="col-span-1">
            <span className="ontrial-h2"> {matter.getName()}</span>
            <br />
            <span className="ontrial-subtitle">{matter.getClientName()}</span>
            <br />
            <span className="ontrial-info">Service Ordered: {order.getService()}</span>
          </div>
          <div className="col-span-1 text-right">
            <span className="ontrial-info">
              Opened {order.getOpenDate()}{" "}
            </span>
            <br />
            
          </div>
        </div>
      </div>
    </>
  );
};

const MatterPanelCard = ({ matter, matters, formManager }) => {
  const [isAtTop, setIsAtTop] = useState(true);
  const handleScroll = e => {
    e.persist();
    let scrollPosition = e.target.scrollTop;
    if (scrollPosition !== 0 && isAtTop === true) {
      setIsAtTop(false);
    } else if (scrollPosition === 0 && isAtTop === false) {
      setIsAtTop(true);
    }
  };

  const onNewOrderClick = () => {
    formManager.override("newOrder", matter);
  };

  // console.dir({ matter, formManager })

  return (
    <>
      <div className="card card-panel active hide-scrollbar">
        <div className="col-span-2">
          <span className="ontrial-h1"> {matter.getDisplayName()} </span>
          <br />
          <span className="ontrial-subtitle">
            {" "}
            {matter.getClientName()}
          </span>
          <br />
          <p className="ontrial-info"> Opened {matter.getOpenDate()}</p>
          <hr />
          <div className="connect-block">
            <span className={"ontrial-h2"}> Orders </span>
          </div>
          <div className="connect-block text-right">
            <button className="btn-primary" onClick={onNewOrderClick}> NEW ORDER </button>
          </div>
          <br />
          <br />
          <div
            className={`card-panel-rolodex hide-scrollbar ${
              isAtTop ? "" : "not-top"
              }`}
            onScroll={handleScroll}
          >
            {matter.getOrders().length > 0 ? (
              matter
                .getOrders()
                .map((o, n) => (
                  <OrderCard
                    key={n ^ (6.646 * 8.2945)}
                    order={o}
                    matters={matters}
                    matter={[matter]}
                    active={false}
                    setActive={() => { }}
                    rolodex={true}
                  />
                ))
            ) : (
                <>
                  <p className="ontrial-copy">
                    There are no orders for this matter, yet. Click 'New Order' to
                  create one.
                  </p>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const OrderPanelCard = ({ order, matters }) => {
  let matter = order.getMatter(matters);
  console.log('From order panel card...')
  console.log(order)
  // console.dir({ order, matters })

  return (
    <>
      <div className="card card-panel active hide-scrollbar">
        <div className="card-body">
          <div className="col-span-2">
            <span className="ontrial-h1"> {matter.getName()} </span>
            <br />
            <span className="ontrial-subtitle">
              {" "}
              {matter.getClientName()}
            </span>
            <br />
            {order.isRush() ? (
              <div className="indicator text-center"> RUSH </div>
            ) : (
                ""
              )}
            <span
              className={`card-order-status ${
                order.isOpen() ? "order-open" : "order-closed"
                }`}
            >
              {" "}
              {order.isOpen() ? "OPEN" : "CLOSED"}{" "}
            </span>
            <br/>
            <span className="ontrial-info"> Matter Opened {matter.getOpenDate()}</span>
            <br/>
            <span className="ontrial-info"> Order Opened {order.getOpenDate()}</span>
            <br />
            <hr />
          </div>

          <div className="col-span-2">
            <h2 className="ontrial-h2"> Service </h2>
            <span className="ontrial-copy">{order.getService()}</span>
            <br />
            <br />
             <h2 className="ontrial-h2"> Notes </h2>
            {order.getNotes().map((note, n) => (
              <p className="ontrial-copy" key={n ^ (7 * 0.4445)}>
                {note}
              </p>
            ))}
            {/* {(order.getDeadline() && order.getDeadline() !== '') ? <span className='card-order-label'> Specified Deadline : {order.getDeadline()} </span> : ''} */}
            <br />
            <br />

            {/* <h4> Documents </h4>
            {order.getDocuments().length > 0
              ? order.getDocuments().map((doc, n) => {
                return (
                  <div
                    key={n ^ (44 * 0.000656)}
                    className="document-wrapper container"
                  >
                    <div className="row">
                      <div className="col-lg-5">
                        <span className="document-title"> {doc.title} </span>
                      </div>
                      <div className="col-lg-5">
                        <span className="document-type"> .{doc.type} </span>
                      </div>
                      <div className="col-lg-2"></div>
                    </div>
                  </div>
                );
              })
              : ""} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default {
  Matter: MatterCard,
  Order: OrderCard,
  Panel: {
    Matter: MatterPanelCard,
    Order: OrderPanelCard
  },
  Plain: PlainCard
};
