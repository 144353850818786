import React from 'react'
import './account.css'
import Card from '../card'
import clio_logo from '../../../App/vendor/media/Clio-Horizontal-Grey.png'
import Api from '../../../api'
import CONSTANTS from "../../../api/constants";

const ConnectClio = ({ connectionFlowInProgress, redirectFailed, reload, catchRedirectError }) => {

    const handleTransferEvent = (function (event) {
        return async (event) => {
            event.preventDefault()
            console.log(event)
            if (event.isTrusted && event.key === 'redirect' && event.newValue !== null) {
                try {
                    console.log("Got it!")
                    console.log(event.newValue)
                    let transferObject = JSON.parse(event.newValue)
                    console.log(transferObject)
                    let response = await Api.Resources.User.connect(transferObject.code)
                    window.localStorage.clear()
                    window.localStorage.setItem('close', 'close')
                    if (response.error) {
                        console.log(response.error)
                        // return catchRedirectError()
                        await reload()
                        window.location.href = '/matters'
                    }
                    await reload()
                    window.location.href = '/matters'
                    
                } catch (e) {
                    await reload()
                    window.location.href = '/matters'
                }
            }
            return event
        }
    })



    return (
        <>
            <div className="connect-block">
            {
                connectionFlowInProgress ?
                    (
                        redirectFailed ?
                            <>
                                <label className='attempt-error'> Oops! Something went wrong connecting your account. </label>
                            </> : <>
                                <label> Please navigate to the new tab and follow the steps to login with Clio </label>
                            </>
                    ) :
                    <>
                        <span className="ontrial-copy"> Uh oh! It doesn't look like you've connected your Clio account yet! To get the most out of your OnTrial experience, connect to Clio now!</span>
                        <br />
                        <br />
                        <div className='connectButton'>
                            <button className='btn-secondary' onClick={async function (event) {
                                let { uri } = await Api.getClioRedirectUri()
                                window.localStorage.clear()
                                window.open(uri, "_blank")
                                window.onstorage = (e) => handleTransferEvent(e)(e)
                            }}> Connect </button>
                        </div>
                    </>

            }
            </div>
        </>
    )
}

class Account extends React.Component {
    constructor(props) {
        super(props)
        this.parentState = props.state
        console.log('Account - parent state')
        console.log(this.parentState)
        this.state = {
            user: this.parentState.user,
            details: {
                username: '',
                firstName: '',
                lastName: '',
                companyName: '',
                address: '',
                city: '',
                state: '',
                postalCode: ''
            },
            detailsModified: false,
            detailsLoaded: false,
            connectionFlowInProgress: false,
            redirectFailed: false,
            input:{secret1:"" , secret2:""}
        }
        this.form = {}
        this.handleTransferEvent = (e) => {
            e.preventDefault()
            console.log(e)
        }
        this.updatePassword = this.updatePassword.bind(this);

    }

    async componentDidMount() {
        let { details, error } = await Api.Resources.User.getInfo()
        console.log(details)
        if (error || !details) {
            // alert('Ooops!')
        }
        let _state = this.state
        _state.details = details
        _state.detailsLoaded = true
        this.setState({ ..._state })


    }

    catchRedirectError() {
        let _state = this.state
        _state.connectionFlowInProgress = true
        _state.redirectFailed = true
        this.setState({ ..._state })
    }

    onInputChange(e) {
        let _state = this.state
        if (!_state.detailsModified) {
            _state.detailsModified = true
        }
        _state.details[e.target.name] = e.target.value
        this.setState({ ..._state })
    }

    updatePassword(e){
        e.preventDefault();
        if (this.state.input.secret1 !== this.state.input.secret2 ){
            alert("Error: the passwords do not match!");
        }
        else if (this.state.input.secret1.length < 6){
            alert("Error: the password must be at least 6 characters");
        }
        else{

            let updatePasswordEvent = new Api.Events.User.UpdatePasswordEvent(Api.constants.events.user.updatePassword, {secret:this.state.input.secret1}, ()=>{});
            updatePasswordEvent.submit().then(value => {
                alert("Success: the password has been updated!");
                this.setState({ input:{
                        secret1: "",
                        secret2: ""
                    } });
            }, reason => {
                alert("Password update failed!")
            });

        }


    };

    render() {
        return (<>
            <div id='account-container'>
                <div id='account-main'>
                    <h1 className={"ontrial-h1"}> My Account </h1>
                    <hr id="my-account-hr" />
                    <div className='account-section-container'>


                        <div className='account-section-body'>
                            <Card.Plain variant={'card-variant-account'}>
                            <h3 className={"ontrial-h2"}> Integration Settings </h3>

                                <div className="connect-block">
                                    <img
                                        className="clio-logo"
                                        src={clio_logo}
                                        alt="Whoops!"
                                        onClick={() => {
                                            window.open("https://clio.com/", "_blank");
                                        }}
                                    />
                                </div>

                                {
                                    this.state.user.hasClioConnection() ?
                                        <span className="ontrial-copy"> You're connected! Now you can create OnTrial Matters from pre-existing Clio Matters, and send Documents straight to OnTrial as you submit an Order! </span>:

                                        <ConnectClio
                                            connectionFlowInProgress={this.state.connectionFlowInProgress}
                                            redirectFailed={this.state.redirectFailed}
                                            reload={() => this.props.reload()}
                                            catchRedirectError={() => this.catchRedirectError()}

                                        />


                                }
                            </Card.Plain>
                        </div>
                    </div>

                    <Card.Plain variant={"card-variant-account"}>
                    <div className='account-section-container'>
                        <div className='account-section-heading'>
                            <h3 className="ontrial-h2"> Contact Information </h3>
                        </div>
                        <div className='account-section-body'>
                            {
                                this.state.detailsLoaded ?
                                    <form id='account-info-form'>
                                        <input
                                            className='account-form-input form-half ontrial-info'
                                            placeholder='First Name'
                                            name='firstName'
                                            value={this.state.details.firstName}
                                            ref={(elem) => this.form.firstName = elem}
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled
                                        />
                                        <input
                                            className='account-form-input form-half-latter ontrial-info'
                                            placeholder='Last Name'
                                            name='lastName'
                                            value={this.state.details.lastName}
                                            ref={(elem) => this.form.lastName = elem}
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled
                                        />
                                        <input
                                            className='account-form-input form-full ontrial-info'
                                            placeholder='Firm/Company Name'
                                            name='companyName'
                                            value={this.state.details.companyName}
                                            ref={(elem) => this.form.companyName = elem}
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled
                                        />
                                        <input
                                            className='account-form-input form-full ontrial-info'
                                            placeholder='Street Address'
                                            name='streetAddress'
                                            value={this.state.details.address}
                                            ref={(elem) => this.form.address = elem}
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled
                                        />
                                        <input
                                            className='account-form-input form-half ontrial-info'
                                            placeholder='City'
                                            name='city'
                                            value={this.state.details.city}
                                            ref={(elem) => this.form.city = elem}
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled
                                        />
                                        <input
                                            className='account-form-input form-partial ontrial-info'
                                            placeholder='State'
                                            name='state'
                                            value={this.state.details.state}
                                            ref={(elem) => this.form.state = elem}
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled
                                        />
                                        <input
                                            className='account-form-input form-partial ontrial-info'
                                            placeholder='Zip Code'
                                            name='zipCode'
                                            value={this.state.details.postalCode}
                                            ref={(elem) => this.form.postalCode = elem}
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled
                                        />
                                    </form> :
                                    <p> Loading... </p>
                            }
                        </div>
                    </div>
                    </Card.Plain>

                    <Card.Plain variant={"card-variant-account"}>
                        <form onSubmit={this.updatePassword}>
                            <h3 className="ontrial-h2"> Reset Password </h3>

                            <label> Password </label>
                            <input
                                className="form-input ontrial-info"
                                type="password"
                                placeholder="******"
                                value={this.state.input.secret1}
                                onChange={e => {
                                    // e.preventDefault();
                                    // e.persist();


                                    this.setState({ input:{
                                            secret1: e.target.value,
                                            secret2: this.state.input.secret2
                                        } });
                                }}
                            />
                            <label> Re-Enter Password </label>
                            <input
                                className="form-input ontrial-info"
                                type="password"
                                placeholder="******"
                                value={this.state.input.secret2}
                                onChange={e => {
                                    // e.preventDefault();
                                    // e.persist();


                                    this.setState({ input:{
                                            secret1: this.state.input.secret1,
                                            secret2: e.target.value
                                        } });
                                }}
                            />

                            <button type="submit" className="btn-primary">
                                {" "}
                                Reset Password{" "}
                            </button>
                        </form>
                    </Card.Plain>



                </div>
                <img src="/static/media/Plants_R1 (1).2521de4c.png" className="plants" alt="Whoops!"></img>
            </div>


        </>
        )
    }

}
export default Account