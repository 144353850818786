import React, { useState } from "react";
import login_logo from "../../vendor/media/OnTrial Logo Main.png";
import Api from '../../../api'
import CONSTANTS from "../../../api/constants";
import plants from "../../vendor/media/Plants_R1 (1).png";


const Landing = () => {

  if (sessionStorage.getItem('session') !== null) {
    window.location.href = '/matters'
  }
  const [input, setInput] = useState({ username: "", secret: "" })
  const [attempt, setAttempt] = useState({
    value: CONSTANTS.ATTEMPT.SIGNIN.NONE
  })

  async function onLogin(e) {
    e.preventDefault()
    e.persist()
    const onLoginSuccess = (user) => {
      window.history.pushState({ user: user }, 'login', '/matters')
      window.location.href = '/matters'
    }
    let loginEvent = new Api.Events.User.LoginEvent(Api.constants.events.user.login, input, onLoginSuccess)
    await loginEvent.submit()
  }
  return (
    <>
      <div id="signup-header-block" className='col-span-1 row-span-1' />
      <div className="signup-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 col-lg-3 d-sm-none d-md-block d-lg-block"></div>
            <div className="col-sm-12 col-md-8 col-lg-6">
              <div className="text-center">
                <br />
                <br />

                <img className="logo-md" src={login_logo} alt="Whoops!" />


                {/* <div id='login-box'></div> */}
                <div id='login-form'>
                  <h1> Login </h1>
                  <form onSubmit={onLogin}>
                    <label> Username </label>
                    <input
                      type="text"
                      placeholder="JohnDoe45"
                      value={input.username}
                      onChange={e => {
                        e.preventDefault();
                        e.persist();
                        setInput({
                          username: e.target.value,
                          secret: input.secret
                        });
                      }}
                    />
                    <label> Password </label>
                    <input
                      type="password"
                      placeholder="******"
                      value={input.secret}
                      onChange={e => {
                        e.preventDefault();
                        e.persist();
                        setInput({
                          username: input.username,
                          secret: e.target.value
                        });
                      }}
                    />
                    {attempt.value === CONSTANTS.ATTEMPT.SIGNIN.ERROR ? (
                      <div className="text-center">
                        <p className="attempt-error">
                          {" "}
                          The log in information you provided is incorrect.{" "}
                        </p>
                      </div>
                    ) : (
                        ""
                      )}
                    <button type="submit" className="btn-primary">
                      {" "}
                      Log In{" "}
                    </button>
                  </form>
                  <br />
                  <div className="text-center">
                    <span className="ontrial-info"> Don't have an account? </span>
                    <span className="ontrial-info"> <a href="/enroll">Sign Up Now</a></span>
                    <br />
                  </div>
                  <div className="text-center">
                    <span className="ontrial-info"> Forgot your password? </span>
                    <span className="ontrial-info"> <a href="/reset">Reset Password</a></span>
                    <br />
                  </div>
                </div>

              </div>
            </div>
            {/* <div className="col-md-2 col-lg-3 d-sm-none d-md-block d-lg-block"></div> */}
          </div>
        </div>
        <img src={plants} className="plants" alt="Plants Background" />
        <div id="login-footer-block" ></div>
      </div>


    </>
  );
};

export default Landing;
