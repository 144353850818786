import React from "react";

const Orders = () => {
  return (
    <svg
      className="sidebar-icon"
      width="15px"
      height="12px"
      viewBox="0 0 10 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>Orders Icon</title>
      <desc>Orders Icon</desc>
      <g
        id="New-Orders-B"
        stroke="none"
        strokeWidth="1"        
        fillRule="evenodd"
      >
        <g
          id="OnTrial-Desktop-Open-Orders-R5_ProximaNova"
          transform="translate(-15.000000, -92.000000)"        
        >
          <g id="Left-Nav" transform="translate(-3.000000, 0.000000)">
            <path
              d="M18.0002581,92 L18,97.25 L18.9679688,93.841724 L26,93.841724 L26,92.6138261 L20.4763072,92.6138261 L19.7020096,92 L18.0002581,92 Z M19.2685932,94.6251367 L18,99 L26.8822062,99 L28,94.625 L19.2685932,94.6251367 Z"
              id="Fill-1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const Account = () => {
  return (
    <svg
      className="sidebar-icon"
      width="15px"
      height="12px"
      viewBox="0 0 8 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>Account</title>
      <desc>Account</desc>
      <defs>
        <polygon id="path-1" points="0 0 8 0 8 9 0 9"></polygon>
      </defs>
      <g
        id="New-Orders-B"
        stroke="none"
        strokeWidth="1"      
        fillRule="evenodd"
      >
        <g
          id="OnTrial-Desktop-Open-Orders-R5_ProximaNova"
          transform="translate(-16.000000, -121.000000)"
        >
          <g id="Left-Nav" transform="translate(-3.000000, 0.000000)">
            <g id="Group-3" transform="translate(19.000000, 121.000000)">
              <mask id="mask-2" fill="white">
                <use href="#path-1"></use>
              </mask>
              <g id="Clip-2"></g>
              <path
                d="M4,4.37837838 C2.7575,4.37837838 1.75,3.39835135 1.75,2.18918919 C1.75,0.980027027 2.7575,0 4,0 C5.24275,0 6.25,0.980027027 6.25,2.18918919 C6.25,3.39835135 5.24275,4.37837838 4,4.37837838 M8,8.75675676 C8,8.88981081 7.889,9 7.75175,9 L0.24825,9 C0.11325,9 0,8.89102703 0,8.75675676 C0,6.60745946 1.791,4.86486486 4,4.86486486 C6.20925,4.86486486 8,6.60745946 8,8.75675676"
                id="Fill-1"
                mask="url(#mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};


const Help = () => {
  return (
    <svg
      className="sidebar-icon"
      width="20px"
      height="20px"
      viewBox="0 -1 10 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>?</title>
      <desc>Help</desc>
      <g
        id="New-Orders-B"
        stroke="none"
        strokeWidth="1"        
        fillRule="evenodd"
        fontFamily="Montserrat-ExtraBold, Montserrat"
        fontSize="16"
        fontWeight="600"
      >
        <g
          id="OnTrial-Desktop-Open-Orders-R5_ProximaNova"
          transform="translate(-16.000000, -153.000000)"          
        >
          <text id="?">
            <tspan x="17" y="165">
              ?
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};
const Logout = () => {

    return (

      <svg 
      className="sidebar-icon"
      width="15px"
      height="24px"
      version="1.1" 
      id="Layer_1" 
      xmlns="http://www.w3.org/2000/svg" 
      xlink="http://www.w3.org/1999/xlink" 
      x="-10px" 
      y="0px"
      viewBox="0 -1 57.6 1" 
      enableBackground="new 0 0 57.6 1;" 
      space="preserve"
      >

<path className="st0" fill="#1E1E1E;" d="M56,4.6c0-2.3-1.9-4.2-4.2-4.2c-2.3,0-4.2,1.9-4.2,4.2v35.9c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2V4.6z" 
	/>
<path className="st0" fill="#1E1E1E;" d="M1.6,22.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0,0.2,0.1,0.3s0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1

	c0,0.1,0.1,0.1,0.1,0.2C1.9,24.4,2,24.5,2,24.6l0.2,0.3l0.2,0.3l0.2,0.2c0,0.1,0.1,0.1,0.1,0.1l11.7,11.6c1.7,1.5,4.4,1.3,5.9-0.5
	c1.3-1.6,1.3-3.9,0-5.4l-4.5-4.5h23.3c2.3,0,4.2-1.9,4.2-4.2s-1.9-4.2-4.2-4.2H15.8l4.5-4.5c1.5-1.7,1.3-4.4-0.5-5.9
	c-1.6-1.3-3.9-1.3-5.4,0L2.8,19.6c0,0-0.1,0.1-0.1,0.1L2.5,20l-0.2,0.3l-0.2,0.3C2.1,20.6,2,20.7,2,20.8c0,0.1-0.1,0.1-0.1,0.2
	c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3s0,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3C1.6,22.5,1.6,22.7,1.6,22.9
	L1.6,22.9z"/>
</svg>
    );
};
const Plus = () => {

  return (

    <svg 
    className="sidebar-icon"
    width="13px"
    height="13.5px"
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xlink="http://www.w3.org/1999/xlink" 
    x="-0px" 
    y="5px"
    viewBox="0 0 44.5 44.5"
    enableBackground="new 0 0 24 22.5;" 
    space="preserve"
    >
      <circle className="st0" fill="#CCCBCB" cx="22.5" cy="22.6" r="21.7"/>
      <line className="st1" fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" x1="22.5" y1="7.2" x2="22.5" y2="37.9"/>
      <line className="st1" fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" x1="7.2" y1="22.6" x2="37.8" y2="22.6"/>
</svg>
  );
};
export default {
  Orders,
  Account,
  Help,
  Logout,
  Plus
};
