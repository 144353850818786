import Matter from '../../Matter'
import OnTrialEvent from '../OnTrialEvent'

class MatterEvent extends OnTrialEvent {
    constructor( eventType, data, callback ){
        super (eventType, data, callback)
        this.requiredProperties = [
            this.constants.fieldNames.matter.clientName,
            this.constants.fieldNames.matter.displayNumber,
            this.constants.fieldNames.matter.matterId,
            this.constants.fieldNames.matter.isClio
        ]
        this.validateProperties()
    }

    async submit(){
        let matterObject = {}
        if( this.data.isClio ){
            matterObject = {
                matterId : this.data.matterId,
                displayNumber : this.data.displayNumber,
                clientName : this.data.clientName
            }
            let matterCreated = await Matter.create(matterObject, true)
            return this.callback( matterCreated)
        } else {
            matterObject = { 
                displayNumber : this.data.displayNumber,
                clientName : this.data.clientName
            }
            let matterCreated = await Matter.create(matterObject, false)
            return this.callback(matterCreated)
        }
    }
}

export default MatterEvent