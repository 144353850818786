import User from '../../User'
import OnTrialEvent from '../OnTrialEvent'

class RequestPasswordResetEvent extends OnTrialEvent {
    constructor(eventType, data, callback) {
        super(eventType, data, callback)
        this.requiredProperties = [
            this.constants.fieldNames.user.email,
        ]
        this.validateProperties()
    }

    async submit() {
        let userLogin = await User.requestPasswordReset({ email: this.data.email});
        console.log('From requestPasswordReset event');
        return this.callback();

    }


}

export default RequestPasswordResetEvent