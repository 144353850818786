import OnTrialEvent from '../OnTrialEvent'
// import AppState from '../../AppState'
import Api from '../../../api'
class PageLoad extends OnTrialEvent {
    constructor(eventType, data, callback) {
        super(eventType, data, callback)
        this.requiredProperties = [
            this.constants.fieldNames.appState.location,
            this.constants.fieldNames.appState.tab,
            this.constants.fieldNames.appState.loaded,
            this.constants.fieldNames.appState.user,
            this.constants.fieldNames.appState.matters,
            this.constants.fieldNames.appState.orders,
            this.constants.fieldNames.appState.newOrderOverride,
            this.constants.fieldNames.appState.overrideContent,
            this.constants.fieldNames.appState.history
        ]
        this.validateProperties()
    }

    async 


    async reloadUser() {
        let fetchUserResponse = await Api.Resources.User.fetch()
        if (!fetchUserResponse.error && fetchUserResponse.user) {
            console.log('Got user')
            let user = new Api.Resources.User(fetchUserResponse.user)
            // console.log(appStateObj)
            let appState = new Api.Resources.AppState({
                location : this.data.location,
                tab : null,
                loaded: true,
                user: user,
                orders: user.getOrders(),
                matters: user.getMatters(user.getOrders()),
                overrideContent : null,
                history : null
            })
            console.log('Here is new appState')
            console.log(appState.get())
            return this.callback(appState.get())
        } else {
            console.log('could not get user')
            let appState = new Api.Resources.AppState()
            return this.callback(appState.get())
        }
    }

    async ingest() {
        console.log('Ingesting state')
        let currentHistory = window.history.state
        let sessionToken = sessionStorage.getItem('session')
        if (sessionToken === null) {
            window.location.href = '/'
        }
        if (currentHistory !== null) {
            try {
                console.log('Trying to set state from history')
                let user = new Api.Resources.User(currentHistory.state.user)
                let state = new Api.Resources.AppState({
                    location: currentHistory.state.location,
                    tab: currentHistory.state.tab,
                    loaded: true,
                    user: user,
                    matters: user.getMatters(user.getOrders()),
                    orders: user.getOrders(),
                    newOrderOverride: currentHistory.state.newOrderOverride,
                    overrideContent: currentHistory.state.overrideContent,
                    history: currentHistory.state.history,
                })
                if( Object.keys(state.user).length === 0 ){
                    console.log('Reloading')
                    return await this.reloadUser()
                }
                console.log(state.get())
                return this.callback(state.get())
            } catch {
                console.log('Must load user')
                await this.reloadUser()
            }
        } else {
            console.log('Must load user')
            this.reloadUser()
        }



    }

}

export default PageLoad