import React, { useState, useEffect } from 'react'

import logo from '../../../vendor/media/OnTrial Logo Main.png'
import Api from '../../../../api'
import qs from 'query-string'


const Login = ({ onFormSubmit, onInputChange, input, attempt }) => {
    // console.log(input())
    return (
        <form onSubmit={onFormSubmit}>
            <label> Username </label>
            <input
                type='text'
                name='username'
                placeholder='JohnDoe45'
                value={input.username}
                onChange={(e) => onInputChange(e)}
            />
            <label> Password </label>
            <input
                type='password'
                name='secret'
                placeholder='******'
                value={input.secret}
                onChange={e => onInputChange(e)}
            />
            {
                attempt.value === Api.constants.ATTEMPT.SIGNIN.ERROR ?
                    <div className='text-center'>
                        <p className='attempt-error'> The log in information you provided is incorrect. </p>
                    </div> :
                    ''
            }
            <button type='submit' className='btn-primary' > Log In </button>
        </form>
    )
}

const NewMatter = ({ onFormSubmit, onInputChange, input, attempt }) => {
    return (
        <form className='ontrial-form form-case' onSubmit={onFormSubmit}>
            <div className='form-case-client-details'>
                <div className='form-client-meta-group'>
                    <span className='form-client-meta-label'>Client Name</span>
                    <input
                        className='form-input form-client-meta'
                        type='text'
                        name='clientName'
                        value={input.clientName}
                        onChange={onInputChange}
                        placeholder='Client Name'
                        required
                    />
                </div>
                <div className='form-client-meta-group'>
                    <span className='form-client-meta-label'> Matter Display Name  </span>
                    <input
                        className='form-input form-client-meta'
                        type='text'
                        name='displayNumber'
                        value={input.displayNumber}
                        onChange={onInputChange}
                        placeholder='Joe vs. Volcano'

                    />
                </div>
            </div>
            <br />
            <br />
            <div className='text-center'>
                <button type='submit' className='btn-primary'> Create Matter </button>
            </div>
        </form>
    )
}

class ClioAction extends React.Component {

    constructor(props) {
        super(props)
        this.actionParameters = qs.parse(window.location.search)
        this.subjectRegex = /\/matters\/(\d+)$/
        this.state = {
            verified: false,
            hasSession: false,
            user: null,
            userClioMatters: [],
            subjectId: '',
            displayLoginForm: false,
            displayMatterForm: false,
            form: {
                login: {
                    username: '',
                    secret: ''
                },
                matter: {
                    matterId: '',
                    clientName: '',
                    displayNumber: ''
                }
            },
            attempt: {
                login: Api.constants.ATTEMPT.SIGNIN.NONE
            }

        }

        this.onFormInputChange = (e, form) => {
            e.preventDefault()
            e.persist()
            let _state = this.state
            _state.form[form][e.target.name] = e.target.value
            return this.setState({ ..._state })
        }

        this.onLoginFormSubmit = async (e) => {
            e.preventDefault()
            e.persist()
            console.log('Submit')
            let _state = this.state
            try {
                let userResponse = await Api.Resources.User.login({ ..._state.form.login })
                console.log(userResponse)
                let token = userResponse.token
                let user = userResponse.user
                _state.user = new Api.Resources.User(user)
                let subjectUrl = this.actionParameters.subject_url || null
                if (subjectUrl !== null && this.subjectRegex.test(subjectUrl)) {
                    let subjectId = this.subjectRegex.exec(subjectUrl)[1]
                    _state.subjectId = subjectId
                }
                window.sessionStorage.clear()
                window.sessionStorage.setItem('session', token)
                _state.hasSession = true
                _state.verified = true
                _state.displayLoginForm = false
                let mattersResponse = await Api.Resources.User.getClioMatters()
                // console.log(mattersResponse)
                _state.userClioMatters = mattersResponse.matters
                if (_state.userClioMatters.length > 0) {
                    for (let p = 0; p < _state.userClioMatters.length; p++) {
                        let matter = _state.userClioMatters[p]
                        console.log(matter)
                        console.log(_state.subjectId)
                        if (matter.id == _state.subjectId) {
                            _state.form.matter.matterId = matter.id
                            _state.form.matter.displayNumber = matter.display_number
                            _state.form.matter.clientName = matter.client.name
                            _state.displayMatterForm = true
                            console.log('Setting state')
                            return this.setState({ ..._state })
                        }
                    }
                }



                // return this.setState({ ..._state })
            } catch (e) {
                console.log('error')
                console.log(e)
                if (e instanceof Error) {
                    console.log('is error')
                }
            }

        }

        this.onMatterFormSubmit = async (e) => {
            e.preventDefault()
            e.persist()

            try {
                let response = await Api.Resources.Matter.create(this.state.form.matter, true)
                console.log(response)
                window.location.href = '/matters'
            } catch (e) {
                console.log(e)
            }
        }
    }

    async componentDidMount() {
        let _state = this.state
        if (!this.state.verified && !this.state.user) {
            let session = sessionStorage.getItem('session')
            // console.log('Session' + (session ? ' ' : ' not ') + 'found')
            // console.log(session)
            if (!session) {
                console.log('No session')
                _state.hasSession = false
                _state.displayLoginForm = true
                return this.setState({ ..._state })
            } else {
                try {
                    _state.hasSession = true
                    _state.verified = true
                    _state.displayLoginForm = false
                    let mattersResponse = await Api.Resources.User.getClioMatters()
                    // console.log(mattersResponse)
                    _state.userClioMatters = mattersResponse.matters
                    if (_state.userClioMatters.length > 0) {
                        for (let p = 0; p < _state.userClioMatters.length; p++) {
                            let matter = _state.userClioMatters[p]
                            console.log(matter)
                            console.log(_state.subjectId)
                            if (matter.id == _state.subjectId) {
                                _state.form.matter.matterId = matter.id
                                _state.form.matter.displayNumber = matter.display_number
                                _state.form.matter.clientName = matter.client.name
                                _state.displayMatterForm = true
                                console.log('Setting state')
                                return this.setState({ ..._state })
                            }
                        }
                    }
                } catch (e) {
                    _state.hasSession = false
                    _state.displayLoginForm = true
                    return this.setState({ ..._state })
                }
            }
        }
    }


    renderInteriorContent() {
        if (!this.state.verified && !this.state.hasSession && this.state.displayLoginForm) {
            return (

                <div className='text-center'>
                    <br />
                    <br />
                    <h1> Please log in to continue.</h1>
                    <br />
                    <br />
                    <Login onFormSubmit={(e) => this.onLoginFormSubmit(e)} onInputChange={(e) => this.onFormInputChange(e, 'login')} input={this.state.form.login} attempt={this.state.attempt.login} />
                </div>

            )
        }
        else if (this.state.verified && this.state.user && this.state.displayMatterForm) {
            return (
                <div className='card card-panel'>
                    <div className='text-center'>
                        <br />
                        <img alt='whoops!' src={logo} className='logo-full' />
                        <NewMatter onFormSubmit={(e) => this.onMatterFormSubmit(e)} onInputChange={(e) => this.onFormInputChange(e, 'matter')} input={this.state.form.matter} />
                    </div>
                </div>
            )
        } else {
            return <h1> One moment... </h1>

        }
    }


    render() {
        return (
            <div id='action-clio-container'>
                {this.renderInteriorContent()}
            </div>
        )
    }
}

export default ClioAction