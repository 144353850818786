import Resource from './Resource'
import moment from 'moment'
class Order extends Resource {
    constructor(orderObject) {
        super(orderObject)
        this.id = orderObject.id
        this.caseId = orderObject.case
        this.service = orderObject.serviceName
        this.notes = orderObject.notes
        this.documents = []
        // this.scheduleCall = orderObject.scheduleCall
        this.open = orderObject.isOpen
        this.created = orderObject.dateCreated
        this.updated = orderObject.dateUpdated
        // this.rush = orderObject.isRush
        // this.deadline = orderObject.neededBy
        this.case = {}
    }

    is(id) {
        return (this.id === id)
    }

    getCreatedTime() {
        return this.created
    }

    getOpenDate() {
        return moment(this.getCreatedTime()).format('MM/DD/YYYY')
    }

    getUpdatedTime() {
        return moment(this.updated).format('MM/DD/YYYY')
    }

    getService() {
        return this.service
    }

    getDeadline() {
        return this.deadline
    }

    getNotes() {
        return this.notes.split('\n')
    }

    getId() {
        return this.id
    }

    isOpen() {
        return this.open
    }

    isRush() {
        // return this.rush
        return false
    }

    getDocuments() {
        return this.documents
    }

    getMatter(matters) {
        for (let i = 0; i < matters.length; i++) {
            let matter = matters[i]
            if (matter.is(this.caseId)) {
                return matter
            }
        }
        return null
    }

    static async create(orderObject) {
        if (orderObject.notes === '') {
            orderObject.notes = ' '
        }
        orderObject.service = Number(orderObject.service)
        let { status, data, error } = await this.request({
            path: `/order`,
            method: 'POST',
            body: orderObject
        })
        console.debug(status)
        if (error) {
            // alert('Staging - encountered error \r\n' + error)
        } else {
            return data
        }
    }
}

export default Order