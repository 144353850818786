import React, { useState } from 'react'

import Card from '../card'
import Forms from '../forms'

const FormSwitch = ({ formManager, user, renderDefault, reload }) => {
    return <>
        {
            formManager.state.newMatter.show ?
                <>
                    {/* <div id='form-click-catch' onClick={() => formManager.close('newMatter')} /> */}
                    <Card.Plain>
                        <Forms.NewMatter user={user} reload={reload} formManager={formManager}/>
                    </Card.Plain>
                </> : (
                    formManager.state.newOrder.show ?
                        <>
                            {/* <div id='form-click-catch' onClick={() => formManager.close('newOrder')} /> */}
                            <Card.Plain>
                                <Forms.NewOrder user={user} reload={reload} formManager={formManager}/>
                            </Card.Plain>
                        </> :
                        renderDefault()
                )
        }
    </>

}

export default FormSwitch