import User from '../../User'
import OnTrialEvent from '../OnTrialEvent'

class UpdatePasswordEvent extends OnTrialEvent {
    constructor(eventType, data, callback) {
        super(eventType, data, callback)
        this.requiredProperties = [
            this.constants.fieldNames.user.secret,

        ]
        this.validateProperties()
    }

    async submit() {
        let results = await User.updatePassword({ secret: this.data.secret});
        console.log('From UpdatePasswordEvent event');
        if(results.error){
            throw Error(results.error);
        }
        this.callback(results);
        return results;


    }


}

export default UpdatePasswordEvent