import React from 'react'
import CONSTANTS from '../../../api/constants'
import MyMatters from './MyMatters'
import OpenOrders from './OpenOrders'
import ClosedOrders from './ClosedOrders'
import Api from '../../../api'

class Orders extends React.Component {
    constructor(props) {
        super(props)
        this.parentState = props.state
        console.log('Current tab')
        console.log(this.parentState.tab)
        this.state = {
            tab: this.parentState.tab,
            forms: {
                newMatter: {
                    show: false,
                    hoist: false,
                    content: {}
                },
                newOrder: {
                    show: false,
                    hoist: false,
                    content: {}
                },
            }
        }

        this.changeLocation = props.changeLocation
        this.orders = props.state.orders
        this.matters = props.state.matters
        this.user = props.state.user
        this.reload = props.reload
        this.openOrders = []
        this.closedOrders = []

        for (let i = 0; i < this.orders.length; i++) {
            let order = this.orders[i]
            if (order.isOpen()) {
                this.openOrders.push(order)
            } else {
                this.closedOrders.push(order)
            }
        }

        this.changeTab = (tab) => {
            console.log('Change tab')
            this.changeLocation( Api.constants.events.app.state.type.tab, tab)
        }


        this.hoistFormOpen = ({ form }) => {
            console.log('Form Manager event')
            let _state = this.state
            Object.keys(_state.forms).forEach(f=>_state.forms[f]={
                show: false,
                hoist: false,
                content: {}
            })
            _state.forms[form] = {
                show: true,
                hoise: false,
                content: {}
            }
            this.setState({ ..._state})
        }

        this.hoistFormOpen_ContentOverride = ({ form, content, }) => {
            console.log('Form Manager event')
            let _state = this.state
            Object.keys(_state.forms).forEach(f=>_state.forms[f]={
                show: false,
                hoist: false,
                content: {}
            })
            _state.forms[form] = {
                show: true,
                hoist: true,
                content: content
            }
            this.setState({ ..._state})
        }

        this.hoistFormClose = ({ form }) => {
            console.log('Form Manager event')
            let _state = this.state
            // if( !form ){

            // }
            Object.keys(_state.forms).forEach(f=>_state.forms[f]={
                show: false,
                hoist: false,
                content: {}
            })
          
            
            this.setState({ ..._state})
        }

        this.formManager = {
            state: this.state.forms,
            open: (f) => this.hoistFormOpen({ form: f }),
            close: (f) => this.hoistFormClose({ form: f }),
            override: (f, c)=> this.hoistFormOpen_ContentOverride({ form : f, content : c })
        }

    }

    render() {
        return (
            <>
                {
                    (()=>{
                        switch (this.props.state.tab) {
                            case CONSTANTS.ORDERS_LOCATIONS.OPEN_ORDERS:
                                return <OpenOrders
                                    tab={this.props.state.tab}
                                    changeTab={(t) => this.changeTab(t)}
                                    user={this.user}
                                    matters={this.matters}
                                    orders={this.openOrders}
                                    reload={this.reload}
                                    formManager={this.formManager}
                                />
                            case CONSTANTS.ORDERS_LOCATIONS.CLOSED_ORDERS:
                                return <ClosedOrders
                                    tab={this.props.state.tab}
                                    changeTab={(t) => this.changeTab(t)}
                                    user={this.user}
                                    matters={this.matters}
                                    orders={this.closedOrders}
                                    reload={this.reload}
                                    formManager={this.formManager}
                                />
                            case CONSTANTS.ORDERS_LOCATIONS.MY_CASES:
                            default:
                                return <MyMatters
                                    tab={this.props.state.tab}
                                    changeTab={(t) => this.changeTab(t)}
                                    user={this.user}
                                    matters={this.matters}
                                    orders={this.orders}
                                    reload={this.reload}
                                    formManager={this.formManager}
                                />
                        }
                    })()
                }
            </>
        )
    }


}

export default Orders