import React from 'react'

import login_logo from '../../vendor/media/OnTrial Logo Main.png'

const EnrollmentPending = () => {
    return (
        <>
            <div id="signup-header-block" className='col-span-1 row-span-1' />
            <div className="signup-bg">
                <br />
                <br />
                <div className=" text-center">
                    <div className="col-md-2 col-lg-3 d-sm-none d-md-block d-lg-block"></div>
                    <div className="col-sm-12 col-md-8 col-lg-6"></div>
                    <div className='text-center'>
                        <img className='logo-md' src={login_logo} alt='Whoops!' onClick={() => { window.location.href = '/' }} />
                        <br />
                        <br />
                        <br />
                        <h2> Thank You! </h2>
                        <p className='text-regular'> Your enrollment application for OnTrial has been received! </p>
                        <p className='text-regular'> A Justice Solutions Group representative will reach out to you shortly to confirm your enrollment and billing preferences, and answer any questions you may have about our service. </p>
                        <p className='text-regular'> Since we take security seriously and only attorneys and legal staff may use this application, we confirm and process each user manually. So, thank you for your patience! </p>
                        <p className='text-regular'> If you have an urgent need, please call us at 800-278-9329 or visit our website at <a href="https://jsgfirm.com" rel="noopener noreferrer">jsgfirm.com</a> </p>
                        <p className='text-regular'> Thanks! </p>
                        <p className='text-regular'> The OnTrial Team // Justice Solutions Group </p>
                    </div>
                </div>

            </div>
            <div id='signup-footer-block' className='col-span-1 row-span-1'></div>
        </>
    )
}

export default EnrollmentPending