import Resource from './Resource'

class Document extends Resource {
    constructor(documentObject) {
        super(documentObject)
        this.id = documentObject.id
        this.name = documentObject.name
        this.fileName = documentObject.filename
    }

    static async create(documentObject, isClio) {
        if (isClio) {
            let { status, data, error } = await this.request({
                path: '/document/clio',
                method: 'POST',
                body: documentObject
            })
            console.debug(status)
            if (error) {
                // alert('Staging - encountered error \r\n' + error)
            } else {
                return data
            }
        } else {
            let { status, data, error } = await this.request({
                path: '/document',
                method: 'POST',
                body: documentObject
            })
            console.debug(status)
            if (error) {
                // alert('Staging - encountered error \r\n' + error)
            } else {
                return data
            }
        }
    }
}

export default Document