import CONSTANTS from '../../constants'
import Resource from '../Resource'
import Order from '../Order'

class OnTrialEvent extends Resource {
    constructor ( eventType, data, callback ){
        super( eventType, data, callback )
        this.eventType = eventType
        this.data = data || null
        this.callback = callback || ((res)=>res)
        this.constants = CONSTANTS
        this.requiredProperties = []
    }

    validateProperties(){
        for ( let i = 0; i < this.requiredProperties.length; i++ ){
            if( Object.keys( this.data ).indexOf(this.requiredProperties[i]) < 0){
                throw new Error(`${this.requiredProperties[i]} is a required property`)
            }
        }
    }
    
}

export default OnTrialEvent