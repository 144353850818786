import Resource from './Resource'
class Service extends Resource {
    constructor(serviceObject) {
        super(serviceObject)
        this.id = serviceObject.id
        this.label = serviceObject.label
    }

    getData() {
        return this.data
    }

    getId() {
        return this.id
    }

    getLabel() {
        return this.label
    }

    is(id) {
        return (this.id === id)
    }

    static async getOptions() {
        let { status, data, error } = await this.request({
            path: `/service/options`,
            method: 'GET',
            body: null
        })
        console.debug(status)
        if (error) {
            // alert('Staging - encountered error \r\n' + error)
            return { options : null, error : error }
        } else {
            return { options : data.options, error : null }
        }
    }
}

export default Service