import React from 'react'

import Card from '../card'

const Help = ({ user }) => {

    return (
        <>
            <div className='container-fluid'>
                <Card.Plain plain={true}>
                    <h1> Help </h1>
                    <hr />
                    <h2> Aliqua id deserunt tempor eu commodo excepteur </h2>
                    <p>Aliquip Lorem nisi do id fugiat magna consectetur. Id sit fugiat irure ex et reprehenderit pariatur. Lorem id et officia anim tempor velit consequat ullamco irure. Ipsum laborum aliquip in consequat in incididunt ullamco consequat.

Duis nisi excepteur ut officia velit excepteur sint tempor culpa reprehenderit. Cillum enim culpa cillum aliqua elit irure exercitation sit ullamco aliquip dolore cupidatat. Dolor aliqua excepteur dolore proident ullamco anim commodo commodo duis ea dolore et. Reprehenderit exercitation aliqua pariatur Lorem veniam minim duis commodo. Exercitation cupidatat ipsum laboris dolor incididunt dolor occaecat sint ea velit excepteur minim ullamco culpa. Cupidatat irure duis Lorem dolor occaecat ea tempor.

Eiusmod nostrud laborum magna ullamco occaecat ipsum consequat consectetur fugiat enim sunt. Labore proident eiusmod velit in non voluptate ex nostrud mollit magna pariatur. Pariatur aute elit occaecat laboris incididunt. Eiusmod aliqua eu consequat commodo in eiusmod occaecat pariatur ipsum commodo sit consequat. Eu ad nisi voluptate nostrud sit dolore occaecat ex quis exercitation eu sit.

Aliquip irure voluptate Lorem laborum fugiat. Ut in excepteur nulla eiusmod consequat ipsum officia officia Lorem enim occaecat deserunt elit esse. Incididunt duis esse consequat consectetur eu Lorem mollit ex pariatur dolore.

Veniam ut eiusmod fugiat duis excepteur minim anim do amet qui non id irure in. Ea dolor id dolore fugiat laboris nostrud eiusmod qui incididunt mollit amet velit. Nostrud deserunt irure in aliqua. Ullamco aliqua ut nisi velit esse aute ea ipsum mollit non amet fugiat sunt nisi. Enim in ut ullamco occaecat amet irure irure enim nostrud in. Tempor qui ullamco dolore dolore commodo mollit ex magna laboris.

Dolore cupidatat excepteur deserunt magna est nostrud. Labore reprehenderit duis consectetur reprehenderit quis ullamco nulla. Dolore nulla excepteur laborum nisi anim qui esse. Id duis velit ipsum cupidatat aliqua ea ullamco esse officia pariatur. Aliquip aute labore officia ullamco fugiat adipisicing nulla id. Non pariatur amet consequat voluptate pariatur ex reprehenderit nisi aute nisi sunt cillum.

Consequat proident tempor officia est do cupidatat culpa eu consectetur consequat. Nisi laboris culpa amet deserunt mollit dolor officia dolore. Anim dolore minim ullamco est tempor exercitation quis labore fugiat laboris excepteur cillum. Commodo duis dolor laborum laborum ipsum incididunt adipisicing ad elit. Minim qui quis exercitation ea id adipisicing. Nulla et pariatur veniam eu voluptate occaecat reprehenderit mollit adipisicing duis.</p>
                    <br />
                    <hr />

                </Card.Plain>
            </div>
        </>
    )
}

export default Help