import React from "react";
import Layout from "./Layout";
// New Order Form class component
import Api from "../../../../api";
import moment from "moment";
import Checkbox from "../checkbox";

class NewOrderForm extends React.Component {
    constructor(props) {
        super(props)
        // User object
        this.user = props.user
        // Initial form values
        this.initialFormValues = {
            case: 'default',
            notes: '',
            service: 'default',
            clioDocuments: 'default',
            directDocuments: '',
            saveToClio: false,
            isRush: false,
            scheduleCall: false,
            neededBy: moment().add(14, 'days').format('YYYY-MM-DD')
        }
        this.parentFormData = props.formManager.state.newOrder
        if (this.parentFormData.hoist) {
            this.initialFormValues.case = this.parentFormData.content.id || 'default'
        }

        this.state = {
            form: this.initialFormValues,
            servicesLoaded: false,
            services: [],
            clioDocumentsLoaded: false,
            clioDocuments: [],
            directDocuments: [],
            documentUploadInProgress: false,
            documentUploadStatus: ''
        }

        this.registerClioDocuments = (clioDocumentsResponse) => {
            let _state = this.state
            if (clioDocumentsResponse.documents) {
                console.log('Docs!')
                console.dir(clioDocumentsResponse.documents)
                _state.clioDocuments = clioDocumentsResponse.documents
                _state.clioDocumentsLoaded = true
                console.log(_state.clioDocuments)
                return this.setState({ ..._state })
            }
        }

        if (this.initialFormValues.case !== 'default') {
            Api.Resources.User.getClioDocuments(this.initialFormValues.case).then(this.registerClioDocuments)

        }

        this.isService = (eventName) => {
            for (let p = 0; p < this.state.services.length; p++) {
                let service = this.state.services[p]
                if (service.is(eventName)) {
                    return true
                }
            }
            return false
        }

        this.onInputChange = async e => {
            e.persist();
            let eventName = e.target.name;
            let eventValue = e.target.value;
            let _state = this.state;

            if (eventName === "case") {
                _state.form[eventName] = eventValue;
                this.setState({ ..._state });
                Api.Resources.User.getClioDocuments(this.initialFormValues.case).then(
                    this.registerClioDocuments
                );
            }

            if (eventName === "directDocuments") {
                console.log(eventValue);
                _state.form[eventName] = eventValue;
                return this.setState({ ..._state });
            }

            if (
                this.isService(eventName) ||
                eventName === "isRush" ||
                eventName === "scheduleCall" ||
                eventName === "saveToClio" ||
                eventName === "directDocuments"
            ) {
                _state.form[eventName] = !eventValue;
            } else {
                e.preventDefault();
                _state.form[eventName] = eventValue;
            }

            if (_state.form.notes.length > 250) {
                // alert("Order notes may not exceed 250 characters");
            } else {
                this.setState({ ..._state });
            }
        };

        this.handleFile = async (e, fileName, reader) => {
            let _state = this.state;
            _state.directDocuments.push({ name: fileName, data: reader.result });
            console.log({ name: fileName, data: reader.result });
            return this.setState({ ..._state });
        };

        this.readFile = async file => {
            let fileReader = new FileReader();
            fileReader.onloadend = e => this.handleFile(e, file.name, fileReader);
            fileReader.readAsArrayBuffer(file);
            return;
        };

        this.onFileSelect = async e => {
            e.preventDefault();
            e.persist();
            // console.log(e)
            for (let i = 0; i < e.target.files.length; i++) {
                await this.readFile(e.target.files[i]);
            }
        };

        this.getCurrentMatter = () => {
            return this.user.findMatterById(this.state.form.case);
        };

        this.getMatterName = () => {
            return this.getCurrentMatter().getName();
        };
        this.getMatterClientName = () => {
            return this.getCurrentMatter().getClientName();
        };

        this.onFormSubmit = async e => {
            e.preventDefault();
            e.persist();
            let _state = this.state;
            let orderObject = this.state.form;
            let orderEvObject = this.state.form
            orderEvObject.directDocuments = this.state.directDocuments
            orderEvObject.clioDocumentOptions = this.state.clioDocuments
            let onSubmitSuccess = async (res) => {
                await props.reload()
                window.location.href = '/matters'
            }
            let orderEvent = new Api.Events.Order.NewOrderEvent(Api.constants.events.order.submit, orderEvObject, onSubmitSuccess)
            let response = await orderEvent.submit()

        }

    }


    async componentDidMount() {
        let servicesResponse = await Api.Resources.Service.getOptions();
        let services = servicesResponse.options.map(s => new Api.Resources.Service(s));
        let _state = this.state;
        _state.servicesLoaded = true;
        _state.services = services;
        return this.setState({ ..._state });
    }

    getMatterOptions() {

        let matter = this.user.getMatters(this.user.getOrders())
        return matter.map((c, n) => {
            return (
                <option key={n ^ (7.6 * 2.4345)} value={c.getId()}>
                    {" "}
                    {`${c.getName()} - ${c.getOpenDate()}`}
                </option>
            );
        });
    }

    renderServiceOptions() {
        return (
            <select
                name="service"
                className="form-input ontrial-info"
                value={this.state.form.service}
                onChange={this.onInputChange}
            >
                <option value="default"> Select a service </option>
                {this.state.services.map((service, n) => (
                    <option key={(n * 45) ^ (2 * 0.625)} value={service.getId()}>
                        {service.getLabel()}
                    </option>
                ))}
            </select>
        );
    }

    render() {
        if (!this.state.servicesLoaded) {
            return (
                <>
                    <div className="text-center">
                        <h1> Loading ... </h1>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div id="form-wrapper">


                        <form
                            className="ontrial-form form-case"
                            onSubmit={this.onFormSubmit}
                        >
                            <span className="ontrial-h1"> New Order:  {this.getMatterName()}</span>


                            <div id="matter-dropdown"
                            >
                                <select
                                    className="form-input"
                                    name="case"
                                    value={this.state.form.case}
                                    onChange={this.onInputChange}
                                    disabled
                                >
                                    <option value="default"> Select a Matter </option>
                                    {this.getMatterOptions()}
                                </select>
                            </div>
                            {/* {this.state.form.case !== "" &&
                                this.state.form.case !== "default" ? (
                                    <Layout.ClientMeta
                                        clientName={this.getMatterClientName()}
                                        openDate={this.getCurrentMatter().getOpenDate()}
                                        orderCount={this.getCurrentMatter().getOrderCount()}
                                    />
                                ) : (
                                    <></>
                                )} */}
                            <br />

                            <span class="ontrial-subtitle">{this.getMatterClientName()}</span>
                            <br />
                            <span class="ontrial-info">Matter Created: {this.getCurrentMatter().getOpenDate()} </span>
                            <br />
                            <span class="ontrial-info">Current Order Count: {this.getCurrentMatter().getOrderCount()}</span>
                            <br />
                            <br />
                            <span class="ontrial-info">Service(s) Requested </span>

                            {this.renderServiceOptions()}


                            <br />

                            <span class="ontrial-info">Notes </span>
                            <div className="text-center">
                                <textarea
                                    className="form-input form-textarea"
                                    placeholder="Please provide us with all details relevant to the order"
                                    name="notes"
                                    value={this.state.form.notes}
                                    onChange={this.onInputChange}
                                    rows="4"
                                />
                            </div>
                            <span class="ontrial-info"> Documents </span>
                            {this.user.hasClioConnection() ? (
                                this.state.clioDocumentsLoaded ? (
                                    <select
                                        className="form-input"
                                        name="clioDocuments"
                                        value={this.state.form.clioDocuments}
                                        placeholder="Select documents from Clio"
                                        onChange={this.onInputChange}
                                    >
                                        <option value="default">
                                            {" "}
                                            Select a document from Clio{" "}
                                        </option>
                                        {this.state.clioDocuments.length > 0
                                            ? this.state.clioDocuments.map((doc, n) => {
                                                return (
                                                    <option key={(n * 3.6236) ^ 256.45} value={doc.id}>
                                                        {doc.name}
                                                    </option>
                                                );
                                            })
                                            : ""}
                                    </select>
                                ) : this.state.form.case !== "default" ? (
                                    <p className="text-meta"> Loading documents... </p>
                                ) : (
                                            ""
                                        )
                            ) : (
                                    ""
                                )}
                            <div className="form-user-document-group">
                                <input
                                    className="form-input select-user-document ontrial-info"
                                    type="file"
                                    placeholder="Upload a Document"
                                    name="directDocuments"
                                    // value={this.state.form.directDocuments}
                                    onChange={this.onFileSelect}
                                    ref={elem => (this.documentUploadRef = elem)}
                                />
                            </div>

                            <div>
                                <div className="form-service-group">
                                    <input
                                        className="form-service-checkbox"
                                        type="checkbox"
                                        name="saveToClio"
                                        value={this.state.form.saveToClio}
                                        onChange={this.onInputChange}
                                    />
                                    <span className="form-service-label"> Save to Clio? </span>

                                    <br />
                                    <div className="form-service-group">
                                        <input
                                            className="form-service-checkbox"
                                            type="checkbox"
                                            name="isRush"
                                            value={this.state.form.isRush}
                                            onChange={this.onInputChange}
                                        />
                                        <span className="form-service-label">
                                            Is this a rush order?
                                        </span>
                                    </div>
                                    <br />
                                </div>
                                <span className="ontrial-info">
                                    Date Needed By
                                </span>
                                <br />
                                <input
                                    className="form-input ontrial-info"
                                    maxLength={250}
                                    type="date"
                                    placeholder={moment()
                                        .add(14, "days")
                                        .format("yyyy-MM-dd")}
                                    value={this.state.form.neededBy}
                                    onChange={this.onInputChange}
                                    name="neededBy"
                                />
                                <div className="form-service-group">
                                    <span className="ontrial-info">

                                        Would you like to schedule a call with OnTrial?{" "}
                                    </span>
                                    <select
                                        className="form-input ontrial-info"
                                        name="scheduleCall"
                                        value={this.state.form.scheduleCall}
                                        onChange={this.onInputChange}
                                    >
                                        <option value="no"> No </option>
                                        <option value="yes"> Yes </option>
                                    </select>


                                </div>
                            </div>
                            {this.state.form.case !== "" &&
                                this.state.form.case !== "default" ? (
                                    <div>
                                        <br />
                                        <button type="submit" className="btn-primary">
                                            {" "}
                                            Send Order to OnTrial{" "}
                                        </button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                        </form>
                    </div>
                </>
            );
        }
    }
}

export default NewOrderForm;