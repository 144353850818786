import dotenv from "dotenv"
import rp from 'request-promise-native'
import Resources, { User, Matter, Order, Service, Document } from './resources'
import Events from './resources/events'
import CONSTANTS from './constants'
dotenv.config()

class Api {
    constructor() {
        this.User = User
        this.Matter = Matter
        this.Order = Order
        this.Service = Service
        this.Service = Service
        this.Document = Document
    }

    static Resources = Resources
    static constants = CONSTANTS
    static Events = Events

    static async request ( requestObject ){
        let response = await rp({
            transform : ( body, response )=>{
                if( response.statusCode === 401 ){
                    return window.location.href='/'
                }
                return body
            }
        })
        return response
    }

    static async getClioRedirectUri() {
        try {
            let response = await rp({
                uri: `${process.env.REACT_APP_BE_URL}/connect/clio/uri`,
                method: 'GET',
                headers : {
                    'X-LH-AUTH' : sessionStorage.getItem('session')
                },
                json : true
            })
            if( !response.uri ){
                return { uri : null, error : 'Could not get' }
            }
            return { uri : response.uri, error : null }

        } catch (e) {
            return { uri : null, error : e }
        }
    }

}

export default Api
