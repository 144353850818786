import Api from '../../../api'
import OnTrialEvent from '../OnTrialEvent'
class NavigationEvent extends OnTrialEvent {
    constructor(eventType, data, callback) {
        super(eventType, data, callback)
        this.requiredProperties = [
            this.constants.fieldNames.appState.type,
            this.constants.fieldNames.appState.loc,
            'state'
        ]
        this.validateProperties()
        this.appState = new Api.Resources.AppState(data.state)
    }

    ingest() {
        let locationHref = '/matters'
        switch (this.data.type) {
            case this.constants.events.app.state.type.tab:
                switch (this.data.loc) {
                    case this.constants.locations.orders.closedOrders:
                        locationHref = '/orders/closed'
                        this.appState = this.appState.modify({
                            tab: this.constants.locations.orders.closedOrders
                        })
                        break
                    case this.constants.locations.orders.openOrders:
                        locationHref = '/orders/open'
                        this.appState = this.appState.modify({
                            tab: this.constants.locations.orders.openOrders
                        })
                        break
                    case this.constants.locations.orders.myMatters:
                    default:
                        locationHref = '/matters'
                        this.appState = this.appState.modify({
                            tab: this.constants.locations.orders.myMatters
                        })
                        break
                }
                break
            case this.constants.events.app.state.type.dashboard:
            default:
                switch (this.data.loc) {
                    case this.constants.locations.dashboard.account:
                        locationHref = '/account'
                        this.appState = this.appState.modify({
                            location: this.constants.locations.dashboard.account
                        })
                        break
                    case this.constants.locations.dashboard.help:
                        locationHref = '/help'
                        this.appState = this.appState.modify({
                            location: this.constants.locations.dashboard.help
                        })
                        break
                    case this.constants.locations.dashboard.orders:
                    default:
                        locationHref = '/matters'
                        this.appState = this.appState.modify({
                            location: this.constants.locations.dashboard.orders
                        })
                        break
                }
                break
        }
        window.history.pushState({ state : this.appState.get()}, `${this.appState.get().location} ${this.appState.get().tab}`, locationHref)
        return this.callback(this.appState.get())
    }


}

export default NavigationEvent