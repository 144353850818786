import LoginEvent from './LoginEvent'
import SignupEvent from './SignupEvent'
import RequestPasswordResetEvent from './RequestPasswordResetEvent'
import UpdatePasswordEvent from './UpdatePasswordEvent'

export default {
    LoginEvent,
    SignupEvent,
    RequestPasswordResetEvent,
    UpdatePasswordEvent
}