import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import './vendor/css/bootstrap-grid.css'
import './index.css'
import './layout.css'

import Pages from "./pages"



const App = ()=> {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path = "/" component={Pages.Landing} />
        <Route exact path = "/enroll" component={Pages.SignUp} />
        <Route exact path = "/enroll/create" component={Pages.CreateAccount} />
        <Route path = "/enroll/pending" component={Pages.EnrollmentPending} />
        
        <Route path = "/home" component={Pages.Dashboard} />
        <Route path = "/matters" component={Pages.Dashboard} />
        <Route path = "/orders/open" component={Pages.Dashboard} />
        <Route path = "/orders/closed" component={Pages.Dashboard} />
        <Route path = "/account" component={Pages.Dashboard} />
        <Route path = "/help" component={Pages.Dashboard} />
        <Route path = "/password/reset" component={Pages.Dashboard} />
        <Route path = "/reset" component={Pages.RequestPasswordReset} />



        <Route path = "/action/clio" component={Pages.Redirect.Action.Clio} />
        <Route path = "/redirect/clio" component={Pages.Redirect.Auth} />

      </Switch>
    </BrowserRouter>
  )
}

export default App