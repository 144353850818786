import OnTrialEvent from '../OnTrialEvent'
import Api from '../../..'


class OrderEvent extends OnTrialEvent {
    constructor( eventType, data, callback ){
        super ( eventType, data, callback )
        this.requiredProperties = [
            this.constants.fieldNames.order.case,
            this.constants.fieldNames.order.notes,
            this.constants.fieldNames.order.service,
            this.constants.fieldNames.order.isRush,
            this.constants.fieldNames.order.scheduleCall,
            this.constants.fieldNames.order.neededBy,
            this.constants.fieldNames.order.clioDocuments,
            this.constants.fieldNames.order.clioDocumentOptions,
            this.constants.fieldNames.order.directDocuments
        ]
        this.validateProperties()
        this.orderCreated = null
        this.onResolve = ((resolveValue)=> ( this.callback ? this.callback(resolveValue) : resolveValue ) )

    }

    async submit(){
        let orderObject = {
            case : this.data.case,
            notes: this.data.notes,
            service : this.data.service,
            isRush : this.data.isRush,
            scheduleCall : this.data.scheduleCall,
            neededBy : this.data.neededBy
        }

        this.orderCreated = await Api.Resources.Order.create(orderObject)
        
        this.clioDocsCreated = []
        this.directDocsCreated = []

        if( this.data.clioDocuments !== 'default' ){
            for ( let q = 0; q < this.data.clioDocumentOptions.length ; q++ ){
                let doc = this.data.clioDocumentOptions[q]
                if( this.data.clioDocuments === doc.id ){
                    let response = Api.Resources.Document.create(
                        {
                            documentId : doc.id,
                            filename : doc.filename,
                            order : this.orderCreated.id
                        },
                        true
                    )
                    this.clioDocsCreated.push(response)
                    console.log(response)
                }
            }
        }

        if ( this.data.directDocuments.length > 0){
            for ( let p = 0; p < this.data.directDocuments.length; p++ ){
                let file = this.data.directDocuments[0]
                let fileBuffer = Buffer.from( file.data )
                let body = {
                    name : file.name,
                    data : fileBuffer.toString('base64'),
                    order : this.orderCreated.id
                }
                let response = Api.Resources.Document.create(body, false)
                this.directDocsCreated.push(response)
            }
        }

        await Promise.all(this.clioDocsCreated)
        await Promise.all(this.directDocsCreated)

        return this.callback({ 
            orderCreated: this.orderCreated, 
            directDocsCreated : this.directDocsCreated, 
            clioDocsCreated : this.clioDocsCreated
        })

    }
}

export default OrderEvent