import React from "react";
import logo from "../../vendor/media/OnTrial Logo Main.png";
import "./dashboard.css";
import constants from "../../../api/constants";

import Orders from "../../components/orders";
import Account from "../../components/account";
import Help from "../../components/help";
import Api from "../../../api";
import Icons from "../../components/icons";
import plants from "../../vendor/media/Plants_R1 (1).png"
import qs from "query-string";

// Dashboard higher-order component
class Dashboard extends React.Component {
  // Constructor
  constructor(props) {
    super(props);
    let params = qs.parse(window.location.search);
    console.log(params);

    if (Object.keys(params).indexOf('token')>-1) {
      window.localStorage.clear();
      sessionStorage.setItem('session', params.token);

    }

    this.initialAppState = new Api.Resources.AppState().get()

    if(this.props.location.pathname === '/account'){

      this.initialAppState.location =Api.constants.locations.dashboard.account;
    }
    this.state = this.initialAppState

  }

  async reload() {
    const onReload = (state) => this.setState({ ...state })
    let appState = new Api.Resources.AppState(this.state)
    await appState.reloadUser(onReload)
  }

  async lastDitchLoad() {
    await this.reload()
  }

  async componentDidMount() {
    console.log('appState')
    this.appState = new Api.Resources.AppState({ ...this.state })
    console.log(this.appState.get())
    const onStateIngest = (outputState) => this.setState({ ...outputState })
    this.pageLoadEvent = new Api.Events.App.PageLoad(Api.constants.events.app.pageLoad, this.appState.get(), onStateIngest)
    await this.pageLoadEvent.ingest()
  }

  async changeLocation(type, loc) {
    console.log('Location Change')
    console.log('Type: ' + type)
    console.log('Location: ' + loc)
    const onModify = (state) => {
      console.log('Navigation - modified state')
      console.log(state)
      this.setState({ ...state })
    }
    let navigationEvent = new Api.Events.App.NavigationEvent(Api.constants.events.app.navigation, { type: type, loc: loc, state: new Api.Resources.AppState({ ...this.state }) }, onModify)
    await navigationEvent.ingest()
  }

  renderCurrentLocation() {
    console.log('Function render current location')
    console.log(this.state)
    if (!this.state.loaded) {
      return <h1> Loading... </h1>;
    }
    switch (this.state.location) {
      case constants.DASHBOARD_LOCATIONS.ACCOUNT:
        return (
          <Account
            state={this.state}
            changeLocation={(t, l) => this.changeLocation(t, l)}
            reload={() => this.reload()}
          />
        );
      case constants.DASHBOARD_LOCATIONS.HELP:
        return (
          <Help
            state={this.state}
            changeLocation={(t, l) => this.changeLocation(t, l)}
            reload={() => this.reload()}
          />
        );
      case constants.DASHBOARD_LOCATIONS.ORDERS:
      default:
        return (
          <Orders
            state={this.state}
            changeLocation={(t, l) => this.changeLocation(t, l)}
            newOrderOverride={this.state.newOrderOverride}
            overrideContent={this.state.overrideContent}
            reload={() => this.reload()}
          />
        );
    }
  }

  render() {
    return (
      <>

        <div id="dashboard-container">
          <div id="dashboard-header-block" className="col-span-2 row-span-1">
            {" "}
          </div>
          <div id="dashboard-menu" className="col-span-1 row-span-1">
            <div className="text-center">
              <img
                className="dashboard-sidebar-logo"
                src={logo}
                alt="Whoops!"
                onClick={() => {
                  window.location.href = "/";
                }}
              />
            </div>
            <div id="dashboard-sidebar-tabs-container">
              <div
                className={`dashboard-sidebar-tab ${
                  this.state.location === "orders" ? "active" : ""
                  }`}
                onClick={() => this.changeLocation(Api.constants.events.app.state.type.dashboard, Api.constants.locations.dashboard.orders)}
              >
                <h4>
                  {" "}
                  <Icons.Orders /> Orders{" "}
                </h4>
              </div>
              <div
                className={`dashboard-sidebar-tab ${
                  this.state.location === "account" ? "active" : ""
                  }`}
                onClick={() => this.changeLocation(Api.constants.events.app.state.type.dashboard, Api.constants.locations.dashboard.account)}
              // onClick={() => this.setLocation("account")}
              >
                <h4>
                  {" "}
                  <Icons.Account /> My Account{" "}
                </h4>
              </div>
              {/* <div
                className={`dashboard-sidebar-tab ${
                  this.state.location === "help" ? "active" : ""
                }`}
                  onClick={() => this.changeLocation(Api.constants.events.app.state.type.dashboard, Api.constants.locations.dashboard.help)}
                
              >
                <h4>
                  {" "}
                  <Icons.Help /> Help{" "}
                </h4>
              </div> */}
              <hr id="side-menu-hr" />
              <div className='dashboard-sidebar-tab' onClick={() => {
                window.sessionStorage.clear()
                window.location.href = '/'
              }}>
                <h4><Icons.Logout /> Log Out </h4>
              </div>
            </div>
          </div>
          <div id="dashboard-main" className="col-span-1 row-span-1">

            {this.renderCurrentLocation()}


          </div>

          <div id="dashboard-footer-block" className="col-span-2 row-span-1"></div>

        </div>

      </>
    );
  }
}

export default Dashboard;
