import Resource from './Resource'

import moment from 'moment'
import Order from './Order'
class Matter extends Resource {
    constructor(matterObject, allOrders) {
        super(matterObject, allOrders)
        this.id = matterObject.id || null
        this.clioId = matterObject.matterId || null
        this.displayName = matterObject.matterName || null
        this.clientName = matterObject.clientName || null
        this.orders = []
        this.dateCreated = matterObject.dateCreated
        this.dateUpdated = matterObject.dateUpdated
        this.documents = matterObject.documents || null
        this.openOrderCount = 0
        this.closedOrderCount = 0
        if (Object(matterObject).hasOwnProperty('orders')) {
            this.orders = matterObject.orders.map(o => new Order({ ...o }))
        }

        for (let p = 0; p < allOrders.length; p++) {
            let order = allOrders[p]
            if (order.caseId === this.id) {
                this.orders.push(order)
            }
        }
    }

    getId() {
        return this.id
    }

    getDisplayName() {
        return this.displayName
    }

    is(id) {
        if (id === this.id) {
            return true
        } else {
            return false
        }
    }

    getName() {
        return this.displayName
    }

    getOpenDate() {
        return moment(this.dateCreated).format('MM/DD/YYYY')
    }

    getClientName() {
        return this.clientName
    }

    getClientInfo() {
        return this.getClientName()
    }

    getOrders() {
        return this.orders
    }

    getOrderCount() {
        return this.orders.length
    }

    getOpenOrderCount() {
        this.openOrderCount = 0
        this.orders.forEach(o => {
            if (o.isOpen() === true) {
                this.openOrderCount++
            }
        })
        return this.openOrderCount
    }

    getClosedOrderCount() {
        this.closedOrderCount = 0
        this.orders.forEach(o => {
            if (o.isOpen() === false) {
                this.closedOrderCount++
            }
        })
        return this.closedOrderCount
    }

    getFiles() {
        return this.documents
    }

    getFileCount() {
        return this.documents.length
    }

    static getOrders(matterId, orders) {
        let _orders = []
        for (let p = 0; p < orders.length; p++) {
            let order = orders[p]
            if (order.caseId == matterId) {
                _orders.push(order)
            }
        }
        return _orders
    }

    static async create(matterObject, isClio) {

        if (isClio) {
            matterObject.matterId = Number(matterObject.matterId)
            let { status, data, error } = await this.request({
                path: '/case/clio',
                method: 'POST',
                body: matterObject
            })
            console.debug(status)
            if (error) {
                // alert('Staging - encountered error \r\n' + error)
            } else {
                return data
            }
        } else {
            delete matterObject.id
            delete matterObject.matterId
            let { status, data, error } = await this.request({
                path: '/case',
                method: 'POST',
                body: {
                    clientName: matterObject.clientName,
                    caseName: matterObject.displayNumber
                }
            })
            console.debug(status)
            if (error) {
                // alert('Staging - encountered error \r\n' + error)
            } else {
                return data
            }
        }

    }
}

export default Matter