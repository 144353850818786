import User from '../../User'
import OnTrialEvent from '../OnTrialEvent'

class SignupEvent extends OnTrialEvent {
    constructor(eventType, data, callback) {
        super(eventType, data, callback)
        this.requiredProperties = [
            this.constants.fieldNames.user.firstName,
            this.constants.fieldNames.user.lastName,
            this.constants.fieldNames.user.email,
            this.constants.fieldNames.user.phone,
            this.constants.fieldNames.user.company,
            this.constants.fieldNames.user.role,
            this.constants.fieldNames.user.address,
            this.constants.fieldNames.user.city,
            this.constants.fieldNames.user.state,
            this.constants.fieldNames.user.postalCode,
            this.constants.fieldNames.user.username,
            this.constants.fieldNames.user.secret
        ]
        this.validateProperties()
    }

    async submit (){
        let userCreated = await User.create(this.data)
        if(!userCreated.user || userCreated.error !== null){
            this.callback(userCreated)
        } else {
            window.location.href = '/enroll/pending'
        }
    }
}

export default SignupEvent