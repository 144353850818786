import Resource from './Resource'
import faker from 'faker'
import Order from "./Order"
import Matter from "./Matter"
class User extends Resource {
    constructor(userObject) {
        super(userObject)
        this.id = userObject.id || null
        this.firstName = userObject.firstName || null
        this.lastName = userObject.lastName || null
        this.email = userObject.email || null
        this.phone = userObject.phone || null
        this.companyName = userObject.companyName || null
        this.role = userObject.role || null
        this.address = userObject.address || null
        this.addressContinued = userObject.addressContinued || null
        this.city = userObject.city || null
        this.state = userObject.state || null
        this.postalCode = userObject.postalCode || null
        this.username = userObject.username || null
        this.secret = userObject.secret || null
        this.matters = userObject.cases || null
        this.orders = userObject.orders || null
        this.clioConnected = userObject.clioConnected || null
        this.clioConnectionDetails = userObject.clioConnectionDetails || null
        this.created = userObject.dateCreated || null
        this.updated = userObject.dateUpdated || null
        this.enrollmentStatus = userObject.enrollmentStatus || null
    }

    enrollment() {
        return {
            isComplete: () => (this.enrollmentStatus === 'complete'),
            status: () => this.enrollmentStatus
        }
    }

    getTerms() {
        let termSectionCount = faker.random.number({ min: 5, max: 40 })
        let createdCount = 0
        let terms = []

        while (createdCount < termSectionCount) {
            let sectionHeading = faker.company.bsAdjective() + ' ' + faker.company.bsNoun()
            let sectionLength = faker.random.number({ min: 3, max: 15 })
            terms.push({
                heading: sectionHeading,
                body: faker.lorem.paragraphs(sectionLength)
            })
            createdCount++
        }
        return terms
    }


    hasClioConnection() {
        return (this.clioConnected === true)
    }

    getMatters(orders) {
        return this.matters.map(m => new Matter(m, orders))
    }

    findMatterById(id) {
        let _matters = this.getMatters(this.getOrders())
        for (let n = 0; n < _matters.length; n++) {
            let c = _matters[n]
            if (c.is(id)) {
                return c
            }
        }
        return null
    }

    getOrders() {
        return this.orders.map(o => new Order(o))
    }

    findOrder(id) {
        let orders = this.getOrders()
        for (let n = 0; n < orders.length; n++) {
            let order = orders[n]
            if (order.is(id)) {
                return order
            }
        }
        return null
    }

    getCreatedTime() {
        return this.created
    }

    getUpdatedTime() {
        return this.updated
    }


    static async getClioDocuments(caseId) {
        let { status, data, error } = await this.request({
            path: `/document/clio?case=${caseId}`,
            method: 'GET',
            body: null
        })
        console.debug(status)
        if (error) {
            // alert('Staging - encountered error \r\n' + error)
        } else {
            return data
        }
    }

    static async getClioMatters() {
        let { status, data, error } = await this.request({
            path: `/user/matters`,
            method: 'GET',
            body: null
        })
        console.debug(status)
        if (error) {
            // alert('Staging - encountered error \r\n' + error)
        } else {
            return data
        }

    }

    static async getInfo() {
        let { status, data, error } = await this.request({
            path: `/user/details`,
            method: 'GET',
            body: null
        })
        console.debug(status)
        if (error) {
            // alert('Staging - encountered error \r\n' + error)
        } else {
            let [fn, ln] = data.details.name.split(' ')
            data.details.firstName = fn
            data.details.lastName = ln
            return { details: data.details, error: null }
        }
    }

    static async connect(code) {
        let { status, data, error } = await this.request({
            path: `/connect/clio`,
            method: 'POST',
            body: { code: code }
        })
        console.debug(status)
        if (error) {
            // // alert('Staging - encountered error \r\n' + error)
            console.log(error)
        } else {
            return { user: data.user, error: null }
        }
    }

    static async fetch() {
        let { status, data, error } = await this.request({
            path: `/user`,
            method: 'GET',
            body: null
        })
        console.debug(status)
        if (error) {
            // // alert('Staging - encountered error \r\n' + error)
            sessionStorage.clear()
            window.location.href ='/'
            return { user: null, error: error }
        } else {
            return { user: data.user, error: null }
        }
    }

    static async login({ username, secret }) {

        let { status, data, error } = await this.request({
            path: `/login`,
            method: 'POST',
            body: { username, secret },
            noCredentials: true
        })
        console.debug(status)
        console.log(data)
        console.log(error)
        if (error) {
            if (!error.data) {
                return { token: null, user: null, error: error }
            } else{
                return { token : null, user : null, error : error.data.error }
            }
        } else {
            return { token: data.token, user: data.user, error: null }
        }
    }

    static async create(createUserObject) {
        let { status, data, error } = await this.request({
            path: `/enroll`,
            method: 'POST',
            body: createUserObject,
            noCredentials: true
        })
        console.debug(status)
        if (error) {
            // alert('Staging - encountered error \r\n' + error)
            return { user: null, error: error }
        } else {
            return { user: data, error: null }
        }
    }

    static async requestPasswordReset(createUserObject) {
        let { status, data, error } = await this.request({
            path: '/password/request/reset',
            method: 'POST',
            body: createUserObject,
            noCredentials: true
        })
        console.debug(status)
        if (error) {
            // alert('Staging - encountered error \r\n' + error)
            return { user: null, error: error }
        } else {
            return { user: {}, error: null }
        }
    }
    static async updatePassword(createUserObject) {
        let { status, data, error } = await this.request({
            path: '/password/update',
            method: 'POST',
            body: createUserObject,
        })
        console.debug(status)
        if (error) {
            // alert('Staging - encountered error \r\n' + error)
            return { user: null, error: error }
        } else {
            return { user: {}, error: null }
        }
    }

}

export default User