import rp from 'request-promise-native'
import CONSTANTS from '../constants'
class Resource {
    constructor(...opts) {
        // super(opts)
        this.backendUrl = process.env.REACT_APP_BE_URL
        this.constants = CONSTANTS
    }

    static constants = CONSTANTS

    static async request({ path, method, body, noCredentials = false }) {
        try {
            let opts = {
                uri: `${process.env.REACT_APP_BE_URL}${path}`,
                method: method,
                headers: {
                    
                },
                json: true,
                transform2xxOnly : false,
                transform: (body, response) => {
                    return { status: response.statusCode, data: body }
                },
                simple : false,
            }
            if ( noCredentials ){
            } else {
                opts.headers['X-LH-AUTH'] = sessionStorage.getItem('session')
            }

            if (body) {
                opts.body = body
            }
            let response = await rp(opts)
            console.log(response)
            if (response.status >= 400) {
                return { status: response.status, data: null, error: response }
            }
            return { status: response.status, data: response.data, error: null }
        } catch (e) {
            console.debug('Request Error')
            console.debug({ path, method, body })
            console.debug(e)
            return { status: 'error', data: null, error: e }
        }

    }
}

export default Resource