import React, { useState } from "react";
import login_logo from "../../vendor/media/OnTrial Logo Main.png";
import plants from "../../vendor/media/Plants_R1 (1).png";
import bg from '../../vendor/media/background_login_cropped.jpg'
import Api from '../../../api'
const SignUp = () => {
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    role: "ownerceo",
    address: "",
    addressContinued: "",
    city: "",
    state: 'default',
    postalCode: ""
  });

  const onFormValueChange = e => {
    e.preventDefault();
    e.persist();
    let _input = input;
    _input[e.target.name] = e.target.value;
    setInput({ ..._input });
  };

  const onFormSubmit = e => {
    e.preventDefault();
    window.history.pushState({ ...input }, "signup", "/enroll/create");
    window.location.href = encodeURI("/enroll/create" +  `?firstName=${input.firstName}&lastName=${input.lastName}&email=${input.email}&phone=${input.phone}&company=${input.company}&role=${input.role}&address=${input.address}&addressContinued=${input.addressContinued}&city=${input.city}&state=${input.state}&postalCode=${input.postalCode}`);
  };

  return (
    <>
      <div id='signup-container'>
        <div id="signup-header-block" className='col-span-1 row-span-1' />
        <div className='col-span-1 row-span-1 text-center'>
          <br />
          <img className="logo-md" src={login_logo} alt="Whoops!" />
          <br />
          <div className="form-container">
            <h2 className="form-heading"> Sign Up </h2>
            <form onSubmit={onFormSubmit}>
              <label>First Name</label>
              <span className='required-label'>*</span>
              <input
                type="text"
                name="firstName"
                value={input.firstName}
                onChange={onFormValueChange}
                required
              />
              <label>Last Name</label>
              <span className='required-label'>*</span>
              <input
                type="text"
                name="lastName"
                value={input.lastName}
                onChange={onFormValueChange}
                required
              />
              <label>Email</label>
              <span className='required-label'>*</span>
              <input
                type="email"
                name="email"
                value={input.email}
                onChange={onFormValueChange}
                required
              />
              <label>Phone</label>
              <span className='required-label'>*</span>
              <input
                type="tel"
                name="phone"
                value={input.phone}
                onChange={onFormValueChange}
                required
              />
              <label>Firm/Company Name</label>
              <span className='required-label'>*</span>
              <input
                type="text"
                name="company"
                value={input.company}
                onChange={onFormValueChange}
                required
              />
              <label>Role</label>
              <span className='required-label'>*</span>
              <select
                name="role"
                className="form-input sign-up-select"
                value={input.role}
                onChange={onFormValueChange}
                required
              >
                <option value="attorney"> Attorney </option>
                <option value="paralegal"> Paralegal </option>
                <option value="ownerceo"> Legal Assistant </option>
                <option value="partner"> Other Office Staff </option>
              </select>
              <label>Address</label>
              <span className='required-label'>*</span>
              <input
                type="text"
                name="address"
                value={input.address}
                onChange={onFormValueChange}
                required
              />
              <label>Address Continued</label>
              <input
                type="text"
                name="addressContinued"
                value={input.addressContinued}
                onChange={onFormValueChange}
              />
              <label>City</label>
              <span className='required-label'>*</span>
              <input
                type="text"
                name="city"
                value={input.city}
                onChange={onFormValueChange}
                required
              />
              <label>State/Province</label>
              <span className='required-label'>*</span>
              {/* <input
                type="text"
                name="state"
                value={input.state}
                onChange={onFormValueChange}
                required
              /> */}
              <select className='form-input sign-up-select' name='state' value={input.state} onChange={onFormValueChange} required>
                {
                  Api.constants.forms.signup.fields.state.options.map((opt, n) => {
                    return <option key={(n * 44.6) ^ 65.525} value={opt.value}>
                      {opt.label}
                    </option>
                  })
                }

              </select>
              <br />
              <label>Postal Code</label>
              <span className='required-label'>*</span>
              <input
                type="text"
                name="postalCode"
                value={input.postalCode}
                onChange={onFormValueChange}
                required
              />
              <button type="submit" className="btn-primary">
                {" "}
                Continue{" "}
              </button>
            </form>
          </div>
          <br />
          {/* <hr /> */}
          <div className="text-center">
            <span> Already have an account? </span>
            <br />
            <br />
            <button onClick={() => (window.location.href = "/")}>
              {" "}
              Log In{" "}
            </button>
          </div>
        </div>
        <img src={plants} className="signup-plants" alt="Plants Background" />
        <div id="signup-footer-block" ></div>
      </div>

    </>
  );
};

export default SignUp;
