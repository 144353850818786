import React from "react";
// import moment from "moment";
import { Matter } from "../../../../api/resources";
import Api from "../../../../api";

let initialFormValues = {
  matterId: "default",
  clientName: "",
  displayNumber: ""
};

class NewMatterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: initialFormValues,
      userClioMatters: []
    };
    this.onInputChange = e => {
      e.preventDefault();
      e.persist();
      let _state = this.state;
      _state.form[e.target.name] = e.target.value;
      console.log(e.target.name);
      if (e.target.name === "matterId") {
        console.log("matter id changed");
        console.log(e.target.value);
        let clioMatters = this.state.userClioMatters;
        for (let m = 0; m < clioMatters.length; m++) {
          let clioMatter = clioMatters[m];
          if (clioMatter.id == e.target.value) {
            _state.form.clientName = clioMatter.client ? clioMatter.client.name : null;
            _state.form.displayNumber = clioMatter.display_number;
            return this.setState({ ..._state });
          }
        }
      }

      this.setState({ ..._state });
    };
    this.onFormSubmit = async e => {
      e.preventDefault();
      e.persist();
      let matterCreateObj = this.state.form;
      matterCreateObj.isClio = (this.state.form.matterId !== 'default')
      const onCreateMatterSuccess = async () => {
        await this.props.reload()
        window.location.href = '/matters'
      }
      let matterCreateEvent = new Api.Events.Matter.NewMatterEvent(Api.constants.events.matter.submit, matterCreateObj, onCreateMatterSuccess)
      await matterCreateEvent.submit()
    }
  }

  async componentDidMount() {
    if (this.props.user.hasClioConnection()) {
      let response = await Api.Resources.User.getClioMatters();
      if (!response.matters) {
        console.error("Could not GET user Clio matters");
      } else {
        console.log(response.matters);
        let _state = this.state;
        _state.userClioMatters = response.matters;
        this.setState({ ..._state });
      }
    }
  }

  render() {
    return (
      <>
        <div id="form-wrapper">
          <h1> New Matter </h1>
          <br />
          <form className="ontrial-form form-case" onSubmit={this.onFormSubmit}>
            {this.props.user.hasClioConnection() ? (
              <select
                className="form-order-case-select form-input"
                name="matterId"
                value={this.state.form.matterId}
                onChange={this.onInputChange}
              >
                <option value="default">
                  {" "}
                  ( Optional ) Select a Clio Matter{" "}
                </option>
                {this.state.userClioMatters.length > 0
                  ? this.state.userClioMatters.map((m, n) => {
                    return (
                      <option key={n ^ ((1 / 3) * 486.7)} value={m.id}>
                        {m.client ? m.client.name : 'No Client'} - {m.display_number}
                      </option>
                    );
                  })
                  : ""}
              </select>
            ) : (<>
              <span className="text-meta" id='connect-clio-matter-text'>
                Head to 'My Account' to connect your Clio Account and pull Matters in directly!
                  </span>
              <br />

            </>

              )}
            <br />
            <div className="form-case-client-details">
              <div className="form-client-meta-group">
                <span className="form-client-meta-label">Client Name</span>
                <input
                  className="form-input form-client-meta"
                  type="text"
                  name="clientName"
                  value={this.state.form.clientName}
                  onChange={this.onInputChange}
                  placeholder="Client Name"
                  required
                />
              </div>
              <div className="form-client-meta-group">
                <span className="form-client-meta-label">
                  {" "}
                  Matter Display Name{" "}
                </span>
                <input
                  className="form-input form-client-meta"
                  type="text"
                  name="displayNumber"
                  value={this.state.form.displayNumber}
                  onChange={this.onInputChange}
                  placeholder="Joe vs. Volcano"
                />
              </div>
            </div>
            <br />
            <br />
            <div className="text-center">
              <button type="submit" className="btn-primary">
                {" "}
                Create Matter{" "}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default NewMatterForm;
